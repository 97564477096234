import AppProvider from "context/providers/AppProvider";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
const container = document.getElementById("root");
const root = createRoot(container);
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDg5q6g3xlNHneI5iBWE6Bpq-Cd2LQ2fqA",
  authDomain: "gasinador-b19a5.firebaseapp.com",
  projectId: "gasinador-b19a5",
  storageBucket: "gasinador-b19a5.appspot.com",
  messagingSenderId: "591335593246",
  appId: "1:591335593246:web:5fa1372e4082c61197250f",
  measurementId: "G-M4HFRXJPNB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);
export { db, functions };
root.render(
  <AppProvider>
    <App />
  </AppProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
