// import node module libraries
import FooterWithLinks from "layouts/marketing/footers/FooterWithLinks";
import NavbarLanding from "layouts/marketing/navbars/NavbarLanding";
import { Fragment } from "react";

// import sub components
import AcademyStats from "./AcademyStats";
import CTA from "./CTA";
import Features2Columns from "./Features2Columns";
import HeroAcademy from "./HeroAcademy";
// import SimplePricingPlans from "./SimplePricingPlans";
// import WhatCustomersSay from "./WhatCustomersSay";

const HomeAcademy = () => {
  return (
    <Fragment>
      <NavbarLanding />
      {/* Hero Academy banner section */}
      <HeroAcademy />

      {/* Various acedamy statistics  */}
      <AcademyStats />

      {/* Most Popular Courses */}
      {/* <MostPopularCourses /> */}

      <Features2Columns />

      {/* What our customers say */}
      {/* <WhatCustomersSay /> */}
      {/* <section className="pt-lg-16 pb-lg-14 pt-8 pb-8">
        <Container>
          <SimplePricingPlans />
        </Container>
      </section> */}
      {/* Become an instructor */}
      <CTA />
      <FooterWithLinks />
    </Fragment>
  );
};
export default HomeAcademy;
