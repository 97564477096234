// import node module libraries
import { Col, Container, Row } from "react-bootstrap";
import {
  CalculatorFill,
  CloudFill,
  LightningChargeFill,
  WrenchAdjustable,
} from "react-bootstrap-icons";

// import custom components
import FeatureTopIcon2 from "components/marketing/common/features/FeatureTopIcon2";
import SectionHeadingLeft2 from "components/marketing/common/section-headings/SectionHeadingLeft2";

const Features2Columns = () => {
  const features = [
    {
      id: 1,
      icon: <CalculatorFill />,
      title: "Gestiona tus gastos y proveedores de manera efectiva",
      description: `Nuestra plataforma te permitirá realizar un seguimiento detallado de tus transacciones, así como gestionar tus facturas y pagos de manera efectiva, lo que te ayudará a mejorar la rentabilidad de tu negocio.`,
    },
    {
      id: 2,
      icon: <WrenchAdjustable />,
      title: "Implementación fácil y soporte técnico completo",
      description: `Nuestra solución ERP para gasolineras es fácil de implementar y usar. Nos aseguramos de ofrecerte soporte técnico completo para que puedas aprovechar al máximo nuestra plataforma desde el primer día.`,
    },
    {
      id: 3,
      icon: <CloudFill />,
      title: "Accede fácilmente a informes, bitácoras y estadísticas",
      description: `Podrás obtener una visión general de tus ventas, gastos y rentabilidad en tiempo real, lo que te permitirá tomar decisiones informadas para mejorar la eficiencia y rentabilidad de tu negocio.`,
    },
    {
      id: 4,
      icon: <LightningChargeFill />,
      title: "Optimiza la eficiencia y productividad de tu personal",
      description: `Con una interfaz intuitiva y fácil de usar, tus empleados podrán realizar sus tareas de manera más efectiva, reduciendo errores y tiempos muertos en la gestión de procesos clave.`,
    },
  ];

  return (
    <section className="pb-8 pb-lg-12 bg-white">
      <Container>
        <Row>
          <Col xl={{ offset: 2, span: 8 }} sm={12}>
            <Row>
              <Col lg={9} md={12} sm={12} className="mb-8">
                {/*  Section left heading */}
                <SectionHeadingLeft2
                  title="Software sencillo y poderoso, desde tu celular o computadora."
                  description="Todo lo que necesitas, personaliza y extiende según tu necesidad."
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <Row>
                  {features.map((item, index) => {
                    return (
                      <Col key={index} md={6} sm={12} className="pe-lg-6 mb-lg-6 mb-4">
                        <FeatureTopIcon2 item={item} />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
            {/* <hr className="mt-6" /> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Features2Columns;
