export const settings = {
    app: {
        name: "Geeks React",
        version: "2.0.1"
    }, 
    theme:{
        skin: "light" // possible values are light, dark
    }
};
export default { settings };
