import { mdiArrowLeftBold, mdiArrowRightBold } from "@mdi/js";
import Icon from "@mdi/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "./App.css";
import Month from "./Month";
import { getMonth } from "./util";

const Calendar = ({ data, callbackFn, selectedLocation, currentMonthFn }) => {
  const [currentMonth, setCurrentMonth] = useState(getMonth());
  const [monthIndex, setMonthIndex] = useState(new Date().getMonth());
  const [loading, setLoading] = useState(true);
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre 🇲🇽 🌮",
    "Octubre 👻 🎃",
    "Noviembre 🦃",
    "Diciembre 🎄🎅",
  ];

  const handlePrevMonth = () => {
    setMonthIndex(monthIndex - 1);
  };
  const handleNextMonth = () => {
    setMonthIndex(monthIndex + 1);
  };
  const handleReset = () => {
    setMonthIndex(monthIndex === dayjs().month() ? monthIndex + Math.random() : dayjs().month());
  };

  useEffect(() => {
    setLoading(true);
    setCurrentMonth(getMonth(monthIndex));
    currentMonthFn(monthIndex);
    setLoading(false);
  }, [monthIndex, data]);

  return (
    <div className="h-screen d-flex flex-column">
      <Row className="px-4  d-flex align-items-baseline justify-content-between border-bottom mb-3 pb-3">
        <Col xs={12} lg={6} className="d-flex align-items-center justify-content-center">
          <p className="ml-4 text-xl fs-4 text-gray-500 fw-bold">
            {/* {dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM YYYY")} */}
            {meses[new Date(dayjs(new Date(dayjs().year(), monthIndex))).getMonth()] +
              " " +
              new Date(dayjs(new Date(dayjs().year(), monthIndex))).getFullYear()}
          </p>
        </Col>

        <Col xs={12} lg={6} className="d-flex align-items-center justify-content-center">
          {/* <p className="mr-10 text-xl text-gray-500 fw-bold">Calendar</p> */}
          <div className="d-flex">
            <Button
              variant="primary"
              className="mx-2"
              onClick={(e) => {
                e.preventDefault();
                handlePrevMonth();
              }}
            >
              <Icon path={mdiArrowLeftBold} size={1} />
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                handleReset();
              }}
              className="border rounded py-2 px-4 mr-5"
            >
              Hoy
            </Button>
            <Button
              className="ms-2"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                handleNextMonth();
              }}
            >
              <Icon path={mdiArrowRightBold} size={1} />
            </Button>
          </div>
        </Col>
      </Row>

      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="flex flex-1 mt-4">
          <Month
            month={currentMonth}
            data={data ? data : []}
            callbackFn={callbackFn}
            selectedLocation={selectedLocation}
          />
        </div>
      )}
    </div>
  );
};

export default Calendar;
