import { settings } from "AppConfig";
import { createContext } from "react";

export const MailContext = createContext({ mails: [] });
export const ChatContext = createContext();
export const TaskKanbanContext = createContext({
  taskColumns: [],
  tasks: [],
});
export const AppConfigContext = createContext(settings);

export const DashboardContext = createContext({});
