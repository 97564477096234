// import node module libraries
import StatRightCenterIcon from "components/dashboard/common/stats/StatRightCenterIcon";
import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

// import sub components
import CommonHeaderTabs from "../CommonHeaderTabs";

import { DashboardContext } from "context/Context";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "index";
import React, { useContext, useState } from "react";
import { Dropdown, ListGroup, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

// import bootstrap icons
import { Calendar4 } from "react-bootstrap-icons";

const SalesForecast = () => {
  const [showModal, setShowModal] = useState(false);
  const [businessLocations, setBusinessLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [loading, setLoading] = useState(false);

  const [sugPriceMagna, setSugPriceMagna] = useState(0);
  const [sugPricePremium, setSugPricePremium] = useState(0);

  const [magnaEq, setMagnaEq] = useState({ slope: 0, intercept: 0, error: 0 });
  const [premiumEq, setPremiumEq] = useState({ slope: 0, intercept: 0, error: 0 });

  const [magnaLitersSold, setMagnaLitersSold] = useState(0);
  const [premiumLitersSold, setPremiumLitersSold] = useState(0);

  const [magnaProfit, setMagnaProfit] = useState(0);
  const [premiumProfit, setPremiumProfit] = useState(0);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [magnaCost, setMagnaCost] = useState(0);
  const [premiumCost, setPremiumCost] = useState(0);
  const calculatePriceFn = httpsCallable(functions, "calculatePrices3");

  const db = getFirestore();
  const { user, company, setCompany } = useContext(DashboardContext);

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
    >
      {children}
    </Link>
  ));

  const getLocs = async () => {
    var resp = [];
    const locsRef = collection(db, "companies", user.companyId, "locations");
    const loccSnap = await getDocs(locsRef);
    loccSnap.forEach((e) => {
      resp.push(e.data());
    });
    console.log(resp);
    if (resp.length >= 1) {
      setBusinessLocations(resp);
      setLoading(false);
    }
  };

  const ActionMenu = () => {
    return (
      <div>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>
            <i className="fe fe-more-vertical text-muted"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            {/* <Dropdown.Header>Settings</Dropdown.Header> */}
            <Dropdown.Item
              eventKey="1"
              onClick={(e) => {
                e.preventDefault();
                getLocs().then(() => {
                  setShowModal(true);
                });
              }}
            >
              Realizar Cálculo
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  const handleCalculate = async () => {
    setLoading(true);
    console.log("Calculando...");
    console.log("startDate: ", startDate);
    console.log("endDate: ", endDate);
    console.log("selectedLocation: ", selectedLocation);
    console.log("magnaCost: ", magnaCost);
    console.log("premiumCost: ", premiumCost);
    console.log("companyid: ", user.companyId);

    if (
      startDate &&
      endDate &&
      selectedLocation.value &&
      magnaCost &&
      premiumCost &&
      user.companyId
    ) {
      let startD = new Date(startDate);
      let endD = new Date(endDate);

      await calculatePriceFn({
        startDate: startD,
        endDate: endD,
        locationId: selectedLocation.value.locId,
        costMagna: Number(magnaCost),
        costPremium: Number(premiumCost),
        companyId: user.companyId,
      })
        .then((result) => {
          console.log("result: ", result);

          let mProunded = Math.round(result.data.magna.perfectPrice.suggestedPrice * 100) / 100;
          let pProunded = Math.round(result.data.premium.perfectPrice.suggestedPrice * 100) / 100;

          let mLiters =
            result.data.magna.regression[0] * mProunded ** 2 +
            result.data.magna.regression[1] * mProunded +
            result.data.magna.regression[2];
          let pLiters =
            result.data.premium.regression[0] * pProunded ** 2 +
            result.data.premium.regression[1] * pProunded +
            result.data.premium.regression[2];

          let mProfit = mProunded * mLiters - mLiters * magnaCost;
          let pProfit = pProunded * pLiters - pLiters * premiumCost;

          setMagnaProfit(Math.round(mProfit * 100) / 100);
          setPremiumProfit(Math.round(pProfit * 100) / 100);

          setMagnaLitersSold(Math.round(mLiters * 100) / 100);
          setPremiumLitersSold(Math.round(pLiters * 100) / 100);
          setSugPriceMagna(mProunded);
          setSugPricePremium(pProunded);
          setMagnaEq(result.data.magna.regression);
          setPremiumEq(result.data.premium.regression);
          setLoading(false);
          setShowModal(false);
        })
        .catch((error) => {
          console.log("error: ", error);
          setSugPriceMagna(0);
          setSugPricePremium(0);
          toast.error("Error al calcular precios");
          setLoading(false);
        });
    } else {
      console.log("Faltan datos");
      toast.error("Por favor, llena todos los campos");
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Modal
        centered
        show={showModal}
        size="lg"
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Realizar Cálculo</Modal.Title>
        </Modal.Header>
        {
          // Loading spinner
          loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <Modal.Body>
              <div className="d-flex justify-content-around align-items-center">
                <div>
                  <h5>Fecha inicial</h5>
                  <input
                    type="date"
                    value={startDate}
                    className="form-control"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <h5>Fecha final</h5>
                  <input
                    type="date"
                    value={endDate}
                    className="form-control"
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </div>

                <div>
                  <h5 className="">Sucursal</h5>

                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isClearable={false}
                    defaultValue={selectedLocation}
                    placeholder="Selecciona"
                    value={selectedLocation}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: "40px",
                        minHeight: "40px",
                        minWidth: "15rem",
                        borderRadius: "10px",
                        borderColor: "#ced4da",
                      }),
                    }}
                    // options={[
                    //   { value: user, label: user.email },
                    //   { value: "strawberry", label: "Strawberry" },
                    //   { value: "vanilla", label: "Vanilla" },
                    // ]}
                    options={businessLocations.map((e) => {
                      return { value: e, label: e.name };
                    })}
                    onChange={(e) => {
                      setSelectedLocation(e);
                    }}
                  />
                </div>
              </div>

              <div className="d-flex mt-4">
                <div className="mb-3 mx-3">
                  <label htmlFor="magnaCost" className="form-label text-success">
                    Costo de Gasolina Magna
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="magnaCost"
                    value={magnaCost}
                    onChange={(e) => setMagnaCost(e.target.value)}
                  />
                </div>
                <div className="mb-3 mx-3">
                  <label htmlFor="premiumCost" className="form-label text-danger">
                    Costo de Gasolina Premium
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="premiumCost"
                    value={premiumCost}
                    onChange={(e) => setPremiumCost(e.target.value)}
                  />
                </div>
              </div>
            </Modal.Body>
          )
        }

        <Modal.Footer>
          <button
            className="btn btn-primary"
            disabled={loading}
            onClick={() => {
              // console.log("Calculando...");
              handleCalculate();
            }}
          >
            Calcular
          </button>
        </Modal.Footer>
      </Modal>

      {/* page header tabs */}
      <CommonHeaderTabs />

      <Row>
        <Col xs={12} className="mb-4">
          {/* project summary section */}

          <Card>
            <Card.Header className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="mb-0">Datos para Pronóstico</h4>
                </div>
                <div>
                  <ActionMenu />
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <h4>
                {selectedLocation?.value?.name ? selectedLocation.value.name : "Realiza cálculo"}
              </h4>
              <p>
                {selectedLocation?.value?.fullAddress ? selectedLocation.value.fullAddress : "-"}
              </p>
              <ListGroup variant="flush">
                <ListGroup.Item className="px-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <Calendar4 size={16} className="text-primary" />
                      <div className="ms-2">
                        <h5 className="mb-0 text-body">Fecha de Inicio</h5>
                      </div>
                    </div>
                    <div>
                      <p className="text-dark mb-0 fw-semi-bold">{startDate}</p>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="px-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <Calendar4 size={16} className="text-primary" />
                      <div className="ms-2">
                        <h5 className="mb-0 text-body">Fecha de Término</h5>
                      </div>
                    </div>
                    <div>
                      <p className="text-dark mb-0 fw-semi-bold">{endDate}</p>
                    </div>
                  </div>
                </ListGroup.Item>
                {/* <ListGroup.Item className="px-0">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <Clock size={16} className="text-primary" />
                <div className="ms-2">
                  <h5 className="mb-0 text-body">Estimate Time</h5>
                </div>
              </div>
              <div>
                <p className="text-dark mb-0 fw-semi-bold">30 Days</p>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="px-0">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <CurrencyDollar size={16} className="text-primary" />
                <div className="ms-2">
                  <h5 className="mb-0 text-body">Cost</h5>
                </div>
              </div>
              <div>
                <p className="text-dark mb-0 fw-semi-bold">$18,000</p>
              </div>
            </div>
          </ListGroup.Item> */}
              </ListGroup>

              <div className="d-flex mt-2">
                <div className="w-100 mx-2">
                  <h3 className="text-success mb-0">Magna</h3>
                  <ListGroup variant="flush">
                    <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Costo</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">${magnaCost}</p>
                        </div>
                      </div>
                    </ListGroup.Item>

                    {/* <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Slope</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">-0202</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Intercept</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">22222</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Error</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">22222</p>
                        </div>
                      </div>
                    </ListGroup.Item> */}
                  </ListGroup>
                </div>

                <div className="w-100 mx-2">
                  <h3 className="text-danger mb-0">Premium</h3>

                  <ListGroup variant="flush">
                    <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Costo</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">${premiumCost}</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    {/* <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Slope</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">-0202</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Intercept</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">22222</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="px-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ms-2">
                            <h5 className="mb-0 text-body">Error</h5>
                          </div>
                        </div>
                        <div>
                          <p className="text-dark mb-0 fw-semi-bold">22222</p>
                        </div>
                      </div>
                    </ListGroup.Item> */}
                  </ListGroup>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} className="mb-4">
          {/* project budget section */}

          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div>
              <Card>
                <Card.Header className="card-header">
                  <h4 className="mb-0 text-success">Magna </h4>
                </Card.Header>
                <Row>
                  <Col lg={4} md={12} xs={12}>
                    {/* <StatRightCenterIcon
                  title="Precio Sugerido"
                  value="$21.22"
                  iconName="dollar-sign"
                  iconColorVariant="primary"
                /> */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                      <div>
                        <div className="d-flex align-items-center mb-1">
                          <h1 className="m-0 p-0">$</h1>
                          <input
                            type="number"
                            className="form-control  text-black"
                            id="priceMagna"
                            value={sugPriceMagna}
                            style={{ fontSize: "2em", fontWeight: "bold", lineHeight: "1.2" }} // Style similar to h2
                            onChange={(e) => {
                              console.log(e.target.value);
                              setSugPriceMagna(Number(e.target.value));
                              let total =
                                magnaEq[0] * e.target.value ** 2 +
                                magnaEq[1] * e.target.value +
                                magnaEq[2];
                              setMagnaLitersSold(Math.round(total * 100) / 100);
                              let profit = Number(e.target.value) * total - total * magnaCost;

                              if (profit < 0) {
                                profit = 0;
                              }
                              setMagnaProfit(Math.round(profit * 100) / 100);
                            }}
                          />
                        </div>

                        <p className="mb-0 ">Precio Sugerido</p>
                      </div>
                      <div className="ms-3">
                        <div
                          className={`icon-shape icon-lg bg-light-primary text-primary rounded-circle`}
                        >
                          <i className={`fe fe-dollar-sign fs-3`}></i>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={12} xs={12} className="border-start-md">
                    <StatRightCenterIcon
                      title="Litros Vendidos"
                      value={magnaLitersSold.toLocaleString()}
                      iconName="droplet"
                      iconColorVariant="info"
                    />
                  </Col>
                  <Col lg={4} md={12} xs={12} className="border-start-md">
                    <StatRightCenterIcon
                      title="Utilidad Diaria"
                      value={"$" + magnaProfit.toLocaleString()}
                      iconName="briefcase"
                      iconColorVariant="success"
                    />
                  </Col>
                </Row>
              </Card>
              <br />
              <Card>
                <Card.Header className="card-header">
                  <h4 className="mb-0 text-danger">Premium </h4>
                </Card.Header>
                <Row>
                  <Col lg={4} md={12} xs={12}>
                    <div className="d-flex align-items-center justify-content-between p-4">
                      <div>
                        <div className="d-flex align-items-center mb-1">
                          <h1 className="m-0 p-0">$</h1>
                          <input
                            type="number"
                            className="form-control  text-black"
                            id="pricePremium"
                            value={sugPricePremium}
                            style={{ fontSize: "2em", fontWeight: "bold", lineHeight: "1.2" }} // Style similar to h2
                            onChange={(e) => {
                              console.log(e.target.value);
                              setSugPricePremium(Number(e.target.value));
                              let total =
                                premiumEq[0] * e.target.value ** 2 +
                                premiumEq[1] * e.target.value +
                                premiumEq[2];
                              setPremiumLitersSold(Math.round(total * 100) / 100);
                              let profit = Number(e.target.value) * total - total * premiumCost;
                              if (profit < 0) {
                                profit = 0;
                              }
                              setPremiumProfit(Math.round(profit * 100) / 100);
                            }}
                          />
                        </div>

                        <p className="mb-0 ">Precio Sugerido</p>
                      </div>
                      <div className="ms-3">
                        <div
                          className={`icon-shape icon-lg bg-light-primary text-primary rounded-circle`}
                        >
                          <i className={`fe fe-dollar-sign fs-3`}></i>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={12} xs={12} className="border-start-md">
                    <StatRightCenterIcon
                      title="Litros Vendidos"
                      value={premiumLitersSold.toLocaleString()}
                      iconName="droplet"
                      iconColorVariant="info"
                    />
                  </Col>
                  <Col lg={4} md={12} xs={12} className="border-start-md">
                    <StatRightCenterIcon
                      title="Utilidad Diaria"
                      value={"$" + premiumProfit.toLocaleString()}
                      iconName="briefcase"
                      iconColorVariant="success"
                    />
                  </Col>
                </Row>
              </Card>
            </div>
          )}
        </Col>
      </Row>
      <ToastContainer position="bottom-right" />
    </Fragment>
  );
};

export default SalesForecast;
