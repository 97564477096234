// import node module libraries
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// import MDI icons

// import BS icons

// import sub components
import ContactForm from "./ContactForm";

// import media files
import Logo from "assets/images/brand/logo/logo.png";
// import NavbarLanding from "layouts/marketing/navbars/NavbarLanding";

const Contact = () => {
  return (
    <main>
      <section className="container-fluid bg-white">
        {/* <NavbarLanding /> */}

        <Row className="align-items-center min-vh-100">
          <Col lg={6} md={12} sm={12}>
            <div className="px-xl-20 px-md-8 px-2 py-8 py-lg-0">
              {/* content */}
              <Link to="/" className="mb-4 fs-4 fw-semi-bold">
                {"< "}Volver
              </Link>
              <div className="d-flex justify-content-between mb-7 align-items-center mt-3">
                <Link to="/">
                  <Image src={Logo} alt="" height={80} />
                </Link>
              </div>
              <div className="text-dark">
                <h1 className="display-5 fw-bold">Ponte En Contacto.</h1>
                <p className="lead text-dark">
                  Lléna el formulario para enviarnos un mensaje y en breve nos pondremos en contacto
                  contigo.
                </p>
                <div className="mt-8">
                  <p className="fs-4 mb-4">
                    Si estás buscando suporte, por favor visita nuestro{" "}
                    <Link to="/marketing/help-center/" className="fw-bold">
                      portal de ayuda
                    </Link>
                    .
                  </p>
                  {/* Address */}
                  {/* <p className="fs-4">
                    <Telephone size={16} className="text-primary me-2" /> + 0123-456-7890
                  </p>
                  <p className="fs-4">
                    <EnvelopeOpen size={16} className="text-primary me-2" /> hello@geekui.com
                  </p>
                  <p className="fs-4 ">
                    <GeoAlt size={17} className="text-primary me-2" /> 2652 Kooter Lane Charlotte,
                    NC 28263
                  </p> */}
                </div>

                {/* social media */}
                {/* <div className="mt-10">
                  <Link to="#" className="text-muted me-3">
                    <Icon path={mdiFacebook} size={1} />
                  </Link>
                  <Link to="#" className="text-muted me-3">
                    <Icon path={mdiTwitter} size={1} />
                  </Link>
                  <Link to="#" className="text-muted ">
                    <Icon path={mdiGithub} size={1} />
                  </Link>
                </div> */}
              </div>
            </div>
          </Col>

          {/* right side form section with background color */}
          <Col
            lg={6}
            className="d-lg-flex align-items-center w-lg-50 min-vh-lg-100 position-fixed-lg bg-cover bg-light top-0 right-0"
          >
            <ContactForm />
          </Col>
        </Row>
      </section>
    </main>
  );
};

export default Contact;
