import dayjs from "dayjs";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";

import { toast, ToastContainer } from "react-toastify";

import { DashboardContext } from "context/Context";

export default function Month({ month, data, callbackFn, selectedLocation }) {
  const { user, company, setCompany } = useContext(DashboardContext);

  const [dayEvents, setDayEvents] = useState([]);
  const [daySelected, setDaySelected] = useState({
    day: 0,
    month: 0,
    year: 2023,
    date: new Date(),
  });
  const [showModal, setShowEventModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);
  const [magnaCost, setMagnaCost] = useState(0);
  const [premiumCost, setPremiumCost] = useState(0);
  const [dieselCost, setDieselCost] = useState(0);

  const [businessLocations, setBusinessLocations] = useState([]);

  const db = getFirestore();

  const [selectedEvent, setSelectedEvent] = useState();
  const [filteredEvents, setFilteredEvents] = useState([]);
  // const { setDaySelected, setShowEventModal, filteredEvents, setSelectedEvent } =
  //   useContext(GlobalContext);
  // useEffect(() => {
  //   const events = filteredEvents.filter(
  //     (evt) => dayjs(evt.day).format("DD-MM-YY") === day.format("DD-MM-YY")
  //   );
  //   // setDayEvents(events);
  // }, []);

  const updateGasCosts = async (date) => {
    setLoading(true);

    let dateString = dayjs(date).format("DD-MM-YYYY");

    const archiveRef = await setDoc(
      doc(db, "companies", user.companyId, "dayInformation", dateString),
      {
        date: daySelected.date,
        dateDay: daySelected.day,
        dateMonth: daySelected.month + 1,
        dateYear: daySelected.year,
        magnaCost: magnaCost ? Number(magnaCost) : 0,
        premiumCost: premiumCost ? Number(premiumCost) : 0,
        dieselCost: dieselCost ? Number(dieselCost) : 0,
      },
      { merge: true }
    )
      .then(() => {
        setLoading(false);
        setShowEventModal(false);
        callbackFn(true);
        setMagnaCost(0);
        setPremiumCost(0);
        toast.success("Costos actualizados correctamente");
      })

      .catch((er) => {
        setLoading(false);
        toast.error(er.message);
      });
  };

  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre 🇲🇽 🌮",
    "Octubre 👻 🎃",
    "Noviembre 🦃",
    "Diciembre 🎄🎅",
  ];

  const getDayEvents = (day) => {
    // Extract day, month, and year from the day parameter
    const targetDay = day.date();
    const targetMonth = day.month() + 1;
    const targetYear = day.year();

    const events = data.filter((evt) => {
      return evt.dateDay === targetDay && evt.dateMonth === targetMonth;
    });
    console.log("targetDay: ", targetDay, " - targetMonth: ", targetMonth, "- events: ", events);

    return events;
  };

  function Day({ day, rowIdx }) {
    function getCurrentDayClass() {
      return day.format("DD-MM-YYYY") === dayjs().format("DD-MM-YYYY")
        ? "bg-primary text-white rounded py-1 fw-bold px-1"
        : "";
    }
    let dayInfo = getDayEvents(day);

    return (
      <div
        className="border border-gray-200 d-flex flex-column cursor-pointer calenday"
        onClick={() => {
          // console.log(new Date(day));
          setDaySelected({
            day: new Date(day).getDate(),
            month: new Date(day).getMonth(),
            year: new Date(day).getFullYear(),
            date: new Date(day),
          });
          if (dayInfo.length > 0) {
            setMagnaCost(dayInfo[0].magnaCost);
            setPremiumCost(dayInfo[0].premiumCost);
            setDieselCost(dayInfo[0].dieselCost);
          }

          setShowEventModal(true);
        }}
      >
        <header className="d-flex flex-column items-center w-100">
          {rowIdx === 0 && (
            <p className="text-sm text-left" style={{ marginTop: "-2rem" }}>
              {day.format("ddd").toUpperCase()}
            </p>
          )}
          <div className="text-center">
            <p className={`p-1 my-1 text-center ${getCurrentDayClass()}`}>{day.format("DD")}</p>
          </div>
        </header>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            <div className="d-flex justify-content-between">
              {dayInfo.length > 0 && (
                <div className="w-100">
                  <p className="m-0 text-center">Costos</p>
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <small className="text-success p-0 m-0">
                      M:{" "}
                      {dayInfo[0].magnaCost != null && dayInfo[0].magnaCost != undefined ? (
                        <span>{dayInfo[0].magnaCost}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </small>
                    <small className="text-danger p-0 m-0">
                      P:{" "}
                      {dayInfo[0].premiumCost != null && dayInfo[0].premiumCost != undefined ? (
                        <span>{dayInfo[0].premiumCost}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </small>
                  </div>
                </div>
              )}

              {dayInfo.length > 0 && dayInfo[0][selectedLocation?.locId] && (
                <div className="w-100">
                  <p className="m-0 text-center">Precio Venta</p>
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <small className="text-success p-0 m-0 r">
                      M: <span>{dayInfo[0][selectedLocation.locId].magnaPrice}</span>
                    </small>

                    <small className="text-danger p-0 m-0">
                      P: <span>{dayInfo[0][selectedLocation.locId].premiumPrice}</span>
                    </small>
                  </div>
                </div>
              )}
            </div>

            {dayInfo.length > 0 && dayInfo[0][selectedLocation?.locId] && (
              <div className="w-100">
                <div className="d-flex justify-content-between">
                  <div className="w-100">
                    <p className="m-0 text-center">Ventas</p>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <small className="text-success p-0 mx-1">
                        M:{" "}
                        <span>
                          $ {dayInfo[0][selectedLocation.locId].magnaSalesIncome.toLocaleString()}
                        </span>
                      </small>

                      <small className="text-danger p-0 mx-1">
                        P:{" "}
                        <span>
                          $ {dayInfo[0][selectedLocation.locId].premiumSalesIncome.toLocaleString()}
                        </span>
                      </small>
                    </div>
                  </div>

                  <div className="w-100">
                    <p className="text-center m-0">Litros</p>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <small className="text-success p-0 mx-1">
                        M:{" "}
                        <span>
                          {dayInfo[0][selectedLocation.locId].magnaLitersSold.toLocaleString()}
                        </span>
                      </small>

                      <small className="text-danger p-0 mx-1">
                        P:{" "}
                        <span>
                          {dayInfo[0][selectedLocation.locId].premiumLitersSold.toLocaleString()}
                        </span>
                      </small>
                    </div>
                  </div>
                </div>
                <p className="m-0 text-center"> Utilidad</p>
                <div className="d-flex flex-row align-items-start justify-content-center">
                  <small className="text-success p-0 mx-1">
                    M:{" "}
                    <span>
                      ${" "}
                      {(
                        dayInfo[0][selectedLocation.locId].magnaSalesIncome -
                        dayInfo[0].magnaCost * dayInfo[0][selectedLocation.locId].magnaLitersSold
                      ).toLocaleString()}
                    </span>
                  </small>

                  <small className="text-danger p-0 mx-1">
                    P:{" "}
                    <span>
                      ${" "}
                      {(
                        dayInfo[0][selectedLocation.locId].premiumSalesIncome -
                        dayInfo[0].premiumCost *
                          dayInfo[0][selectedLocation.locId].premiumLitersSold
                      ).toLocaleString()}
                    </span>
                  </small>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  // useEffect(() => {}, [data, change]);

  return (
    <div>
      <Modal centered show={showModal} onHide={() => setShowEventModal(false)} size="xl">
        <Modal.Header className="fs-4 fw-semi-bold">
          {daySelected.day} / {meses[daySelected.month]} / {daySelected.year}
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row">
              <div className="mb-3 mx-3">
                <label htmlFor="magnaCost" className="form-label text-success">
                  Costo de Gasolina Magna
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="magnaCost"
                  value={magnaCost}
                  onChange={(e) => setMagnaCost(e.target.value)}
                />
              </div>
              <div className="mb-3 mx-3">
                <label htmlFor="premiumCost" className="form-label text-danger">
                  Costo de Gasolina Premium
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="premiumCost"
                  value={premiumCost}
                  onChange={(e) => setPremiumCost(e.target.value)}
                />
              </div>

              {/* <div className="mb-3 mx-3">
                <label htmlFor="dieselCost" className="form-label text-black">
                  Costo de Diesel
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="dieselCost"
                  value={dieselCost}
                  onChange={(e) => setDieselCost(e.target.value)}
                />
              </div> */}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex">
          {/* <Button variant="primary" onClick={() => console.log("s")}>
            Agregar Evento
          </Button> */}
          <button
            className="btn btn-primary"
            onClick={() => {
              updateGasCosts(daySelected.date);
              setChange(!change);
            }}
          >
            Guardar
          </button>
        </Modal.Footer>
      </Modal>
      <div
        className=""
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto auto auto auto auto",
          gridAutoColumns: "100%",
          gridGap: "0px",
        }}
      >
        {month.map((row, i) => (
          <React.Fragment key={i}>
            {row.map((day, idx) => (
              <Day day={day} key={idx} rowIdx={i} />
            ))}
          </React.Fragment>
        ))}
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
}
