// import node module libraries
import { Fragment } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// import media files
import ErrorImage from "assets/images/error/404-error-img.svg";

const Error404 = () => {
  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 py-lg-22 py-10">
        <Col xl={{ offset: 1, span: 4 }} lg={6} md={12} className="text-center text-lg-start">
          <h1 className="display-1 mb-3">404</h1>
          <p className="mb-5 lead">
            ¡Ops! Lo siento, no pudimos encontrar la página que estabas buscando. Si piensas que
            esto es un problema con nosotros, por favor{" "}
            <Link to="/contact" className="btn-link">
              <u>contáctanos</u>
            </Link>
          </p>
          <Link to="/" className="btn btn-primary me-2">
            Volver a Inicio
          </Link>
        </Col>
        <Col xl={{ offset: 1, span: 6 }} lg={6} md={12} className="mt-8 mt-lg-0">
          <Image src={ErrorImage} alt="" className="w-100" />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Error404;
