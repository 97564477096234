// import node module libraries
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

// import sub components
import Loader from "components/elements/miscellaneous/Loader";
import { DashboardContext } from "context/Context";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import HeaderDefault from "./HeaderDefault";
import NavbarVertical from "./NavbarVertical";

const DashboardIndex = (props) => {
  const { children, className, overflowHidden } = props;
  const [showMenu, setShowMenu] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();
  const db = getFirestore();

  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});
  const value = {
    user,
    setUser,
    company,
    setCompany,
  };

  const [loading, setLoading] = useState(true);
  const ToggleMenu = () => {
    return setShowMenu(!showMenu);
  };
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
        // const { emailVerified } = user;

        if (user.emailVerified) {
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setUser(docSnap.data());
            if (!docSnap.data().registrationComplete) {
              navigate("/sign-up/company");
            } else {
              const companyRef = doc(db, "companies", docSnap.data().companyId);
              const companySnap = await getDoc(companyRef);
              if (companySnap.exists()) {
                setCompany(companySnap.data());
                setLoading(false);
              }
            }
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
            navigate("/landing");
          }
        } else {
          navigate("/sign-in");
        }
      } else {
        // User is signed out
        navigate("/landing");
      }
    });
  }, []);
  return (
    <DashboardContext.Provider value={value}>
      <div
        id="db-wrapper"
        className={`${overflowHidden ? "chat-layout" : ""} ${showMenu ? "" : "toggled"}`}
      >
        <div className="navbar-vertical navbar">
          <NavbarVertical showMenu={showMenu} onClick={(value) => setShowMenu(value)} />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <section id="page-content">
            <div className="header">
              <HeaderDefault
                data={{
                  showMenu: showMenu,
                  SidebarToggleMenu: ToggleMenu,
                }}
              />
            </div>

            <div className={`container-fluid ${className ? className : "p-4"}`}>
              {children}
              <Outlet />
            </div>
          </section>
        )}
      </div>
    </DashboardContext.Provider>
  );
};
export default DashboardIndex;
