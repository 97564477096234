// import node module libraries
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { functions } from "index";
import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Offcanvas, Row, Table } from "react-bootstrap";

import { MoreVertical } from "react-feather";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useFilters, useGlobalFilter, usePagination, useTable } from "react-table";
// import bootstrap icons
import {
  Exclamation,
  FileEarmarkSlides,
  FileEarmarkSpreadsheet,
  FileImage,
  FilePpt,
  FileText,
  FileWord,
} from "react-bootstrap-icons";

// import custom components
// import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import { Avatar } from "components/elements/bootstrap/Avatar";
import GKTippy from "components/elements/tooltips/GKTippy";

// import utility file
import { DashboardContext } from "context/Context";
import { getFileExtension, getRandomNo } from "helper/utils";
import { toast, ToastContainer } from "react-toastify";

const FilesTable = () => {
  const [show, setShow] = useState(false);
  const db = getFirestore();
  const storage = getStorage();
  const [files, setFiles] = useState([]);

  const [fileUrl, setFileUrl] = useState("");
  const [fileUpload, setFileUpload] = useState();

  const { user, company, setCompany } = useContext(DashboardContext);
  const [businessLocations, setBusinessLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    setLoading(true);
    var resp = [];
    const locsRef = collection(db, "companies", user.companyId, "locations");
    const loccSnap = await getDocs(locsRef);
    loccSnap.forEach((e) => {
      resp.push(e.data());
    });
    console.log(resp);
    if (resp.length >= 1) {
      setBusinessLocations(resp);
      setLoading(false);
    }

    setShow(true);
  };

  const uploadDoc = (event) => {
    if (selectedLocations.length < 1) {
      toast.error("necesitas seleccionar de que suc es el archivo");
      return;
    }
    setLoading(true);
    var companyFolderRef = ref(storage, `companies/${user.companyId}/files/${event.name}`);
    const uploadTask = uploadBytes(companyFolderRef, event)
      .then((snapshot) => {
        return getDownloadURL(companyFolderRef);
      })
      .then(async (url) => {
        console.log(url);
        setFileUrl(url);
        const archiveRef = await addDoc(collection(db, "companies", user.companyId, "files"), {
          name: event.name,
          extension: getFileExtension(event.name),
          dateCreated: new Date(),
          createdBy: user,
          location: { ...selectedLocations.value },
          fileUrl: url,
          filePurpose: "convol",
        });

        const updateRef = doc(db, "companies", user.companyId, "files", archiveRef.id);
        await setDoc(updateRef, { uid: archiveRef.id }, { merge: true });
      })
      .then(() => {
        setLoading(false);
        setShow(false);
        setChange((e) => !e);
        toast.success("¡Información subida correctamente!");
      })
      .catch((er) => {
        setLoading(false);
        console.log(er);
        toast.error(er.message);
      });
  };

  const handleDelete = async (id) => {
    setLoading(true);
    const archiveRef = doc(db, "companies", user.companyId, "files", id);
    await deleteDoc(archiveRef);
    setLoading(false);
    toast.success("¡Información eliminada!");
    setChange(!change);
  };

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
    >
      {children}
    </Link>
  ));

  const ActionMenu = ({ uid, row }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align={"end"}>
          <Dropdown.Item
            eventKey="1"
            className=""
            onClick={(e) => {
              e.preventDefault();
              uploadSalesData(row.original.fileUrl, row.original.location.locId);
            }}
          >
            <i className="fe fe-upload me-2"></i>
            Extraer a base de datos
          </Dropdown.Item>

          {/* <Dropdown.Header>SETTINGS</Dropdown.Header> */}
          <Dropdown.Item
            eventKey="1"
            className="text-danger"
            onClick={(e) => {
              e.preventDefault();

              if (window.confirm("¿Estás seguro de eliminar este archivo?")) {
                handleDelete(uid);
              }
            }}
          >
            <i className="fe fe-trash-2 me-2"></i>
            Eliminar
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getFileTypeIcon = (value) => {
    let color = null;
    let extension = getFileExtension(value);
    extension === "xlsx"
      ? (color = "success")
      : extension === "jpeg"
      ? (color = "info")
      : extension === "doc"
      ? (color = "success")
      : extension === "ppt"
      ? (color = "danger")
      : extension === "txt"
      ? (color = "warning")
      : extension === "mov"
      ? (color = "danger")
      : (color = "danger");

    return (
      <div className={`icon-shape icon-lg rounded-3 bg-light-${color}`}>
        <Link to="#">
          {extension === "xlsx" ? (
            <FileEarmarkSpreadsheet size={24} className="text-success" />
          ) : extension === "jpeg" ? (
            <FileImage size={24} className="text-info" />
          ) : extension === "doc" ? (
            <FileWord size={24} className="text-success" />
          ) : extension === "ppt" ? (
            <FilePpt size={24} className="text-danger" />
          ) : extension === "txt" ? (
            <FileText size={24} className="text-warning" />
          ) : extension === "mov" ? (
            <FileEarmarkSlides size={24} className="text-danger" />
          ) : (
            <Exclamation size={24} className="text-danger" />
          )}
        </Link>
      </div>
    );
  };

  const uploadSalesData = async (fileUrl, locID) => {
    const uploadSalesDataFn = httpsCallable(functions, "uploadSalesData2");

    await uploadSalesDataFn({
      fileUrl: fileUrl,
      companyId: user.companyId,
      locationId: locID,
    })
      .then((result) => {
        console.log(result);
        toast.success("¡Información extraída correctamente!");
      })
      .catch((er) => {
        console.log(er);
        toast.error(er.message);
      });
  };

  const initial = async () => {
    var resp = [];
    const filesRef = collection(db, "companies", user.companyId, "files");
    const q = query(filesRef, where("filePurpose", "==", "convol"), orderBy("dateCreated", "desc"));
    const filesSnap = await getDocs(q);
    filesSnap.forEach((e) => {
      resp.push(e.data());
    });
    setFiles(resp);
  };

  useEffect(() => {
    // {
    // 	id: 1,
    // 	filename: 'Admin-Dashboard-Design.xlsx',
    // 	filesize: '2.3MB',
    // 	date_modified: '27 Sept, 2021',
    // 	avatar: Avatar1,
    // 	uploadedby: 'Paul Haney',
    // 	action: 1
    // },
    initial();
  }, [change]);

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        Header: "Name",
        Cell: ({ value }) => {
          return (
            <div className="d-flex align-items-center">
              {getFileTypeIcon(value)}
              <div className="ms-3">
                <h5 className="mb-0">
                  {/* <Link to="#" className="text-inherit"> */}
                  {value} {/* </Link> */}
                </h5>
              </div>
            </div>
          );
        },
      },

      {
        accessor: "location",
        Header: "Sucursal",
        Cell: ({ value }) => {
          return (
            <div className="d-flex align-items-center">
              <div className="ms-3">
                <h6 className="mb-0">{value.name}</h6>
              </div>
            </div>
          );
        },
      },

      //   { accessor: "filesize", Header: "File Size" },
      {
        accessor: "dateCreated",
        Header: "Subido el",
        Cell: ({ value }) => {
          let dateCreated = new Date(value.seconds * 1000).toLocaleDateString("es-MX");
          return <p>{dateCreated}</p>;
        },
      },
      {
        accessor: "createdBy",
        Header: "Subido por",
        Cell: ({ value, row }) => {
          const bgColor = [
            "primary",
            "secondary",
            "info",
            "success",
            "danger",
            "warning",
            "danger",
            "dark",
          ];
          return (
            <Avatar
              size="sm"
              type="initial"
              name={value.name + " " + value.lastName}
              className={`rounded-circle bg-${bgColor[getRandomNo(0, 7)]}`}
            />
          );
        },
      },

      {
        accessor: "fileUrl",
        Header: "Options",
        Cell: ({ value }) => {
          return (
            <Fragment>
              <GKTippy content="Download">
                <Link to={value} className="me-3 text-muted">
                  <i className="fe fe-download fs-5"></i>
                </Link>
              </GKTippy>
              <GKTippy content="Link">
                {/* <Link to="#" className="me-3 text-muted">
                  <i className="fe fe-link fs-5"></i>
                </Link> */}

                <Button
                  variant="link"
                  className="px-0 pt-0 mt-0 p-0 m-0 text-secondary"
                  onClick={(e) => {
                    const elem = document.createElement("textarea");
                    elem.value = value;
                    document.body.appendChild(elem);
                    elem.select();
                    document.execCommand("copy");
                    document.body.removeChild(elem);
                    toast.success("Texto Copiado");
                  }}
                >
                  <i className="fe fe-link fs-5"></i>
                </Button>
              </GKTippy>
            </Fragment>
          );
        },
      },
      {
        accessor: "uid",
        Header: "",
        Cell: ({ value, row }) => {
          return <ActionMenu uid={value} row={row} />;
        },
      },
    ],
    [change]
  );

  const data = files;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        name="end"
        style={{ width: "600px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title as="h3">Subir Archivo</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          {/* <OffcanvasCreateProjectForm onClick={handleClose} /> */}

          <Form>
            <Row>
              {/* Dirección */}
              <Col xs={12} className="mb-3">
                <Form.Group controlId="formProjectBrief">
                  <Form.Label>
                    Archivo <span className="text-danger">*</span>
                  </Form.Label>
                  {/* <Form.Control
                    as="textarea"
                    rows={3}
                    value={loc}
                    required
                    onChange={(e) => setLoc(e.target.value)}
                    placeholder="Calle número, colonia, estado, país"
                  /> */}

                  <Form.Control
                    type="file"
                    id="const"
                    size="md"
                    onChange={(ev) => {
                      var file = ev.target.files[0];

                      setFileUpload(file);
                    }}
                  />
                </Form.Group>
              </Col>

              {/* Team Members */}
              <Col xs={12} className="mb-3">
                <Form.Group controlId="formTeamMembers">
                  <Form.Label>
                    Sucursal <span className="text-danger">*</span>
                  </Form.Label>
                  {/* <Form.Control
                    as={FormSelect}
                    placeholder="Assign to owner"
                    options={teamMembers}
                  /> */}

                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isClearable={false}
                    defaultValue={selectedLocations}
                    placeholder="Selecciona"
                    value={selectedLocations}
                    // options={[
                    //   { value: user, label: user.email },
                    //   { value: "strawberry", label: "Strawberry" },
                    //   { value: "vanilla", label: "Vanilla" },
                    // ]}
                    options={businessLocations.map((e) => {
                      return { value: e, label: e.name };
                    })}
                    onChange={(e) => {
                      setSelectedLocations(e);
                    }}
                  />
                </Form.Group>
              </Col>

              {/* Buttons */}
              <Col xs={12}>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    uploadDoc(fileUpload);
                  }}
                >
                  Crear
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedLocations([]);
                    handleClose();
                  }}
                  variant="outline-danger"
                  className="ms-2"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      {/* search filename */}
      <Row>
        <Col xxl={2} lg={3} md={12} xs={12} className="mb-4">
          {/* <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            placeholder="Search Filename"
          /> */}
        </Col>
        <Col
          xxl={10}
          lg={9}
          md={12}
          xs={12}
          className="mb-4 d-flex align-items-end justify-content-end"
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleShow();
            }}
          >
            + Subir Archivo
          </Button>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Header>
              <h4 className="mb-0">Files</h4>
            </Card.Header>
            <Card.Body className="p-0">
              <div
                className="table-responsive border-0 overflow-y-hidden"
                style={{ paddingBottom: "9rem" }}
              >
                <Table hover className="text-nowrap mb-0 table-centered">
                  <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()} className="align-middle">
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              {/* Pagination @ Footer */}
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer position="bottom-right" />
    </Fragment>
  );
};

export default FilesTable;
