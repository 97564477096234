export const free = [
  {
    plantitle: "Básico",
    monthly: 5000,
    yearly: 50000,
    buttonText: "Regístrate Ya",
    buttonClass: "primary",
    featureHeading: "All core features, including:",
    features: [
      { feature: "Capacitación", active: true },
      { feature: "Soporte", active: true },
      { feature: "Plantilla de configuración", active: true },
      { feature: "Configuración personalizada", active: true },
      { feature: "Multiusuario", active: true },
      { feature: "Almacenamiento de archivos", active: true },
      { feature: "Hasta 100GB de almacenamiento", active: true },

      { feature: "Tablero de tareas", active: false },
      { feature: "Notificaciones Personalizadas", active: false },
      { feature: "Notificaciones a Terceros", active: false }, // notificar a terceros (fuera del equipo de eventos y recordatorios)

      { feature: "Comparte y envía archivos", active: false },
    ],
  },
];

export const pro = [
  {
    plantitle: "Pro",
    monthly: 7000,
    yearly: 70000,
    buttonText: "Próximamente",
    buttonClass: "outline-secondary disabled",
    featureHeading: "Everything in Starter, plus:",
    features: [
      { feature: "Capacitación", active: true },
      { feature: "Soporte", active: true },
      { feature: "Plantilla de configuración", active: true },
      { feature: "Configuración personalizada", active: true },
      { feature: "Multiusuario", active: true },
      { feature: "Almacenamiento de archivos", active: true },
      { feature: "Hasta 100GB de almacenamiento", active: true },
      { feature: "Tablero de tareas", active: true },
      { feature: "Notificaciones Personalizadas", active: true }, // notificaciones personalizadas como subir un archivo o evento
      { feature: "Notificaciones a Terceros", active: true }, // notificar a terceros (fuera del equipo de eventos y recordatorios)
      { feature: "Comparte y envía archivos", active: true },
    ],
  },
];

export const SimplePricingCard = [free, pro];

export default SimplePricingCard;
