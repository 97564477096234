// import node module libraries
import { Button, Col, Form, Row } from "react-bootstrap";

// import custom components

const ContactForm = () => {
  const contactReason = [
    { value: "Design", label: "Design" },
    { value: "Development", label: "Development" },
    { value: "Other", label: "Other" },
  ];

  return (
    <div className="px-4 px-xl-20 py-8 py-lg-0">
      {/* form section */}
      <div id="form">
        <Form>
          <Row>
            {/* Nombre */}
            <Col md={6} sm={12}>
              <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>
                  Nombre:<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Nombre" required />
              </Form.Group>
            </Col>

            {/* Apellido */}
            <Col md={6} sm={12}>
              <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>
                  Apellido:<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Apellido" required />
              </Form.Group>
            </Col>

            {/* Email */}
            <Col md={6} sm={12}>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>
                  Email:<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control type="email" placeholder="email@ejemplo.com" required />
              </Form.Group>
            </Col>

            {/* Número de Teléfono */}
            <Col md={6} sm={12}>
              <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>
                  Número de Teléfono:<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control type="number" placeholder="00 0000 0000" required />
              </Form.Group>
            </Col>

            {/* Contact Reason */}
            {/* <Col md={12} sm={12}>
							<Form.Group className="mb-3" controlId="formContactReason">
								<Form.Label>
									Contact Reason:<span className="text-danger">*</span>
								</Form.Label>
								<Form.Control
									as={FormSelect}
									placeholder="Select"
									options={contactReason}
									required
								/>
							</Form.Group>
						</Col> */}

            {/* Messages */}
            <Col md={12} sm={12}>
              <Form.Group className="mb-3" controlId="formMessages">
                <Form.Label>Mensaje:</Form.Label>
                <Form.Control as="textarea" placeholder="Mensaje" rows={3} />
              </Form.Group>
            </Col>

            {/* button */}
            <Col md={12} sm={12}>
              <Button variant="primary" type="submit">
                Enviar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
export default ContactForm;
