// import node module libraries
import { Col, Container, Row } from "react-bootstrap";

// import media files
import CTAImg from "assets/images/hero/GasStation2.png";
import Dollor from "assets/images/svg/dollor.svg";
import Graph from "assets/images/svg/graph.svg";

const CTA = () => {
  return (
    <section className="py-lg-14 pb-8 bg-white">
      <Container className="bg-primary rounded-3">
        <Row className="align-items-center">
          <Col lg={6} xs={12} className="d-none d-lg-block">
            <div className="d-flex justify-content-center ">
              <div className="position-relative">
                <img
                  src={CTAImg}
                  alt=""
                  className=" mt-n13"
                  style={{ width: "31rem", marginLeft: "-3rem" }}
                />
                <div className="ms-n12 position-absolute bottom-0 start-0 mb-6">
                  <img src={Dollor} alt="" />
                </div>
                <div className="me-n4 position-absolute top-0 end-0">
                  <img src={Graph} alt="" />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={5} xs={12}>
            <div className="text-white p-5 p-lg-0">
              <h2 className="h1 text-white">Optimiza tu gasolinera hoy mismo</h2>
              <p className="mb-0 font-weight-semi-bold text-dark">
                No esperes más para mejorar la gestión de tu gasolinera, ¡prueba nuestra solución
                ERP especializada hoy mismo y descubre por qué tantos clientes confían en nosotros!
              </p>
              {/* <Link to="/sign-up" className="btn btn-white mt-4 w-100">
                Regístrate ya
              </Link> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CTA;
