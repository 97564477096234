// import node module libraries
import { getAuth, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

// import media files
import Logo from "assets/images/brand/logo/logo-icon.svg";
import Loader from "components/elements/miscellaneous/Loader";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const auth = getAuth();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  //   const [validated, setValidated] = useState(false);

  const signin = (event) => {
    // const form = event.currentTarget;

    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   //   alert("INValid");
    //   //   setValidated(false);
    //   //   setLoading(false);
    // } else {
    //   setValidated(true);

    setLoading(true);
    signInWithEmailAndPassword(auth, email, pw)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        if (user.emailVerified) {
          setLoading(false);
          navigate("/");
        } else {
          sendEmailVerification(auth.currentUser).then(() => {
            // Email verification sent!
            // ...
            navigate("/sign-up/verify-email");
          });
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setLoading(false);
        alert(errorMessage);
      });
    // }
  };
  useEffect(() => {
    setDisabled(!email || !pw);
  }, [email, pw]);
  return (
    <Fragment>
      {loading ? (
        <div
          className="w-100 d-flex align-items-center justify-content-center"
          style={{ minHeight: "10rem" }}
        >
          <Loader />
        </div>
      ) : (
        <Row className="align-items-center justify-content-center g-0 min-vh-100">
          <Col lg={5} md={5} className="py-8 py-xl-0">
            <Card>
              <Card.Body className="p-6">
                <div className="mb-4 d-flex flex-column align-items-center">
                  <Link to="/">
                    <Image src={Logo} className="mb-4" alt="" height={110} />
                  </Link>
                  <h1 className="mb-1 fw-bold">Inicia sesión</h1>
                  {/* <span>
                    ¿Todavía no tienes cuenta?{" "}
                    <Link to="/sign-up" className="ms-1 fw-bold">
                      Regístrate
                    </Link>
                  </span> */}
                </div>
                {/* Form */}
                <Form onSubmit={signin}>
                  <Row>
                    <Col lg={12} md={12} className="mb-3">
                      {/* Username or email */}
                      <Form.Label>Email </Form.Label>
                      <Form.Control
                        type="email"
                        id="email"
                        placeholder="email@ejemplo.com"
                        required
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </Col>
                    <Col lg={12} md={12} className="mb-3">
                      {/* Password */}
                      <Form.Label>Contraseña </Form.Label>
                      <Form.Control
                        type="password"
                        id="password"
                        placeholder="**************"
                        required
                        onChange={(e) => {
                          setPw(e.target.value);
                        }}
                      />
                    </Col>
                    <Col
                      lg={12}
                      md={12}
                      className="mb-1 d-flex align-items-center justify-content-center"
                    >
                      {/* Checkbox */}
                      {/* <div className="d-md-flex justify-content-between align-items-center">
                        <Form.Group className="mb-3 mb-md-0" controlId="formBasicCheckbox">
                          <Form.Check type="checkbox" label="Remember me" />
                        </Form.Group>
                      </div> */}
                      <Link to="/forget-password" className="fw-bold">
                        ¿Olvidáste tu contraseña?
                      </Link>
                    </Col>
                    <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                      {/* Button */}
                      <hr className="my-4" />
                      <Button variant="primary" type="submit" disabled={disabled}>
                        Inicia sesión
                      </Button>
                    </Col>
                  </Row>
                </Form>

                {/* <div className="mt-4 text-center">
                <Link to="#" className="btn-social btn-social-outline btn-facebook">
                  <i className="fab fa-facebook"></i>
                </Link>{" "}
                <Link to="#" className="btn-social btn-social-outline btn-twitter">
                  <i className="fab fa-twitter"></i>
                </Link>{" "}
                <Link to="#" className="btn-social btn-social-outline btn-linkedin">
                  <i className="fab fa-linkedin"></i>
                </Link>{" "}
                <Link to="#" className="btn-social btn-social-outline btn-github">
                  <i className="fab fa-github"></i>
                </Link>
              </div> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default SignIn;
