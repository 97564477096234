// import node module libraries
import { Spinner } from "react-bootstrap";
const Loader = () => {
  return (
    <div
      className="vh-100 loader-bg vw-100 d-flex align-items-center justify-content-center"
      style={{ position: "fixed", top: "0", height: "100vw", width: "100vw" }}
    >
      <Spinner animation="border" variant="primary" className="me-2" />
    </div>
  );
};

export default Loader;
