// import node module libraries
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Fragment, useState } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// import media files
import Logo from "assets/images/brand/logo/logo-icon.svg";

const ForgetPassword = () => {
  const auth = getAuth();

  const [email, setMail] = useState("");

  const sendMail = () => {
    if (email) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          // Password reset email sent!
          alert("Correo enviado");
        })
        .catch((error) => {
          alert(error);
          // ..
        });
    }
  };
  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        <Col lg={5} md={5} className="py-8 py-xl-0">
          <Card>
            <Card.Body className="p-6">
              <div className="mb-4 d-flex flex-column align-items-center">
                <Link to="/">
                  <Image src={Logo} className="mb-4" alt="" height={110} />
                </Link>
                <h1 className="mb-1 fw-bold">Restablecer Contraseña</h1>
                <span>Ingresa tu email para restablecer tu contraseña.</span>
              </div>
              {/* Form */}
              <Form>
                <Row>
                  <Col lg={12} md={12} className="mb-3">
                    {/*  email */}
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      placeholder="email@ejemplo.com"
                      required
                      onChange={(e) => {
                        setMail(e.target.value);
                      }}
                    />
                  </Col>
                  <Col lg={12} md={12} className="mb-3 d-grid gap-2">
                    {/* Button */}
                    <Button
                      variant="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        sendMail();
                      }}
                    >
                      Enviar enlace
                    </Button>
                  </Col>
                </Row>
                <span>
                  Regresar a <Link to="/sign-in">Inicia sesión</Link>
                </span>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ForgetPassword;
