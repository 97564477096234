// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { createUserWithEmailAndPassword, getAuth, sendEmailVerification } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";

// import media files
import Logo from "assets/images/brand/logo/logo-icon.svg";
import Loader from "components/elements/miscellaneous/Loader";

const SignUp = () => {
  const auth = getAuth();
  const db = getFirestore();
  //   const [validated, setValidated] = useState(false);
  const [accept, setAccept] = useState(false);
  const navigate = useNavigate();
  const [pw, setPw] = useState("");
  const [pw2, setPw2] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const register = (event) => {
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // } else {
    //   setValidated(true);
    setLoading(true);
    if (disabled) {
      setLoading(false);
      alert("Por favor llena todos los campos");
    } else {
      if (pw === pw2) {
        createUserWithEmailAndPassword(auth, email, pw)
          .then(async (userCredential) => {
            // Signed in
            const user = userCredential.user;
            await setDoc(doc(db, "users", user.uid), {
              name: name.trim(),
              lastName: lastName.trim(),
              email: email.trim(),
              phone: phone.trim(),
              uid: user.uid,
              isOwner: true,
              registrationComplete: false,
            });
          })
          .then(() => {
            sendEmailVerification(auth.currentUser).then(() => {
              // Email verification sent!
              // ...
              navigate("/sign-up/verify-email");
            });
          })

          .catch((error) => {
            setLoading(false);
            const errorCode = error.code;
            const errorMessage = error.message;
            switch (errorCode) {
              case "auth/email-already-in-use":
                alert(
                  "Ya existe un usuario registrado con este correo electrónico. Favor de iniciar sesión o usar otro correo."
                );
                break;
              case "auth/email-already-exists":
                alert("Ya existe un usuario registrado con este correo electrónico.");
                break;
              case "auth/weak-password":
                alert("Contraseña invalida. Favor de utilizar al menos 6 caracteres.");
                break;
              default:
                alert(errorMessage);
                break;
            }

            // ..
          });
      } else {
        setLoading(true);
        alert("Las contraseñas no coinciden");
      }
    }
    // }
  };

  const checkIfValid = (value) => {
    console.log(value);
    if (
      value != null &&
      value !== "" &&
      value !== " " &&
      value !== undefined &&
      value.trim().length >= 1 &&
      /^[a-zA-ZÁáÉéÍíÓóÚúÑñüÜ\s]+$/g.test(value)
    ) {
      console.log(true);

      return true;
    } else {
      console.log(false);
      return false;
    }
  };
  useEffect(() => {
    setDisabled(
      !checkIfValid(name) || !email || !checkIfValid(lastName) || !phone || !pw || !pw2 || !accept
    );
  }, [name, lastName, email, phone, pw, pw2, accept]);
  return (
    <Fragment>
      {loading ? (
        <div
          className="w-100 d-flex align-items-center justify-content-center"
          style={{ minHeight: "10rem" }}
        >
          <Loader />
        </div>
      ) : (
        <Row className="align-items-center justify-content-center g-0 min-vh-100">
          <Col lg={5} md={5} className="py-8 py-xl-0">
            <Card>
              <div>
                <Card.Body className="p-6">
                  <div className="mb-4 d-flex flex-column align-items-center">
                    <Link to="/">
                      <Image src={Logo} className="mb-4" alt="" height={110} />
                    </Link>
                    <h1 className="mb-1 fw-bold">Regístrate</h1>
                    <span>
                      ¿Ya tienes una cuenta?{" "}
                      <Link to="/sign-in" className="ms-1 fw-bold">
                        Inicia sesión
                      </Link>
                    </span>
                  </div>
                  {/* Form */}
                  <Form onSubmit={register}>
                    <Row>
                      <Col lg={6} md={12} className="mb-3">
                        {/* User Name */}
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="Nombre"
                          //   isValid={checkIfValid(name)}
                          required
                          onChange={(e) => {
                            checkIfValid(e.target.value);
                            setName(e.target.value);
                          }}
                        />
                      </Col>

                      <Col lg={6} md={12} className="mb-3">
                        {/* User Name */}
                        <Form.Label>Apellido</Form.Label>
                        <Form.Control
                          type="text"
                          id="surname"
                          placeholder="Apellido"
                          //   isValid={checkIfValid(lastName)}
                          //   isInvalid={!checkIfValid(lastName)}
                          required
                          onChange={(e) => {
                            checkIfValid(e.target.value);
                            setLastName(e.target.value);
                          }}
                        />
                      </Col>

                      <Col lg={12} md={12} className="mb-3">
                        {/* email */}
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          id="email"
                          placeholder="email@ejemplo.com"
                          required
                          onChange={(e) => {
                            checkIfValid(e.target.value);
                            setEmail(e.target.value);
                          }}
                        />
                      </Col>

                      <Col lg={12} md={12} className="mb-3">
                        {/* email */}
                        <Form.Label>Teléfono celular</Form.Label>
                        <Form.Control
                          type="number"
                          id="phone"
                          placeholder="00 0000 0000"
                          required
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                      </Col>
                      <Col lg={6} md={12} className="mb-3">
                        {/* Password */}
                        <Form.Label>Contraseña </Form.Label>
                        <Form.Control
                          type="password"
                          id="password"
                          placeholder="**************"
                          required
                          onChange={(e) => {
                            setPw(e.target.value);
                          }}
                        />
                      </Col>

                      <Col lg={6} md={12} className="mb-3">
                        {/* Password */}
                        <Form.Label>Confirmar Contraseña </Form.Label>
                        <Form.Control
                          type="password"
                          id="password2"
                          placeholder="**************"
                          required
                          onChange={(e) => {
                            setPw2(e.target.value);
                          }}
                        />
                      </Col>
                      <Col lg={12} md={12} className="mb-3">
                        {/* Checkbox */}
                        <Form.Check type="checkbox" id="check-api-checkbox">
                          <Form.Check.Input
                            type="checkbox"
                            required
                            onChange={(e) => {
                              console.log(e);
                              setAccept(e.target.checked);
                            }}
                          />
                          <Form.Check.Label>
                            Acepto los{" "}
                            <Link to="/pages/terms-and-conditions">Términos y Condiciones </Link> y
                            la <Link to="/pages/terms-and-conditions">Política de Privacidad</Link>
                          </Form.Check.Label>
                        </Form.Check>
                      </Col>
                      <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                        {/* Button */}
                        <hr className="my-4" />

                        <Button variant="primary" type="submit" disabled={disabled}>
                          Regístrate
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default SignUp;
