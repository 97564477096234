// import node module libraries
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Fragment, useEffect } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { getAuth, onAuthStateChanged } from "firebase/auth";
// import media files
import Logo from "assets/images/brand/logo/logo-icon.svg";

const VerifyEmail = () => {
  const auth = getAuth();
  const db = getFirestore();

  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...

        const { emailVerified } = user;

        if (emailVerified) {
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            if (!docSnap.data().registrationComplete) {
              navigate("/sign-up/company");
            }
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
            navigate("/landing");
          }
        }
      } else {
        // User is signed out
        navigate("/landing");
      }
    });
  }, []);

  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        <Col lg={5} md={5} className="py-8 py-xl-0">
          <Card>
            <Card.Body className="p-6">
              <div className="mb-4 d-flex flex-column align-items-center">
                <Link to="/landing">
                  <Image src={Logo} className="mb-4" alt="" height={110} />
                </Link>
                <h1 className="mb-1 fw-bold">Revisa tu correo</h1>
                <p className="text-center">
                  Hemos enviado un correo de verificación a la dirección de correo electrónico que
                  proporcionaste durante el registro.
                  <br /> <br /> Por favor, revisa tu bandeja de entrada (y la carpeta de correo no
                  deseado, en caso de que no encuentres nuestro mensaje) y sigue las instrucciones
                  para completar el proceso de verificación.
                </p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default VerifyEmail;
