// import node module libraries
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Col, Form, Modal, Row, Spinner, Tab } from "react-bootstrap";

// import custom components
import { addDoc, collection, doc, getDocs, getFirestore, setDoc } from "firebase/firestore";
// import bootstrap icons
// import JobSearchBox from "components/marketing/common/jobs/JobSearchBox";
// import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import { Offcanvas } from "react-bootstrap";
// import OffcanvasCreateProjectForm from "../projects/OffcanvasCreateProjectForm";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import Loader from "components/elements/miscellaneous/Loader";
import { DashboardContext } from "context/Context";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "../../../assets/scss/maps.scss";
import LocationGridView from "./LocationGridView";
import LocationListView from "./LocationListView";
const LocationList = () => {
  // const http = require("https");
  const { user, company, setCompany } = useContext(DashboardContext);
  const db = getFirestore();
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [grid, setGrid] = useState(false);
  const [address, setAddress] = useState("");
  const [magna, setMagna] = useState(false);
  const [premium, setPremium] = useState(false);
  const [diesel, setDiesel] = useState(false);
  const [capMagna, setCapMagna] = useState(0);
  const [capPremium, setCapPremium] = useState(0);
  const [capDiesel, setCapDiesel] = useState(0);

  // const [place, setPlace] = useState(null);
  const [addressComponents, setAddressComponents] = useState(null);
  const autocompleteRef = useRef(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDBCTKGrx4ItlFjAjTGcG7ZG07_EU1kN1Q",
    libraries: ["places"],
  });

  const handleShow = () => {
    setName("");
    setLoc("");
    setMembers([]);
    setShow(true);
  };
  const [members, setMembers] = useState([]);
  const [memberOptions, setOptions] = useState([]);
  const [name, setName] = useState("");
  const [loc, setLoc] = useState("");
  const [businessLocations, setBusinessLocations] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    // setPlace(place);

    // Extract the address components from the place object
    const addressComponents = place.address_components.reduce((acc, component) => {
      acc[component.types[0]] = component.long_name;
      return acc;
    }, {});
    // AddressComponents:
    // country, locality (city), administrative_area_level_1 (state), postal_code,
    // street_number, route (street name), sublocality_level_1 (neighborhood)
    setAddressComponents(addressComponents);
    console.log(addressComponents);
    console.log("Ad: ", place.formatted_address);
    // Update the address state with the formatted address
    setAddress(place.formatted_address);
  };

  const checkIfValid = (value) => {
    if (
      value != null &&
      value !== "" &&
      value !== " " &&
      value !== undefined &&
      value.trim().length >= 1
      //    &&
      //   /^[a-zA-ZÁáÉéÍí.,#ÓóÚúÑñüÜ\s]+$/g.test(value)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const createLoc = async () => {
    setLoading(true);
    var cleantMembers = [];
    members.forEach((member) => {
      cleantMembers.push(member.value);
    });
    if (checkIfValid(name) && checkIfValid(address) && members.length >= 1) {
      if (
        checkIfValid(addressComponents.postal_code) &&
        checkIfValid(addressComponents.street_number) &&
        checkIfValid(addressComponents.locality)
      ) {
        const locRef = await addDoc(collection(db, "companies", user.companyId, "locations"), {
          name: name.trim(),
          postal_code: addressComponents.postal_code,
          fullAddress: address,
          street_number: addressComponents.street_number,
          country: addressComponents.country,
          street: addressComponents.route,
          city: addressComponents.locality,
          state: addressComponents.administrative_area_level_1,
          neighborhood: addressComponents.sublocality_level_1,
          dateCreated: new Date(),
          members: cleantMembers,
          products: {
            magna: {
              value: magna,
              capacity: capMagna,
            },
            premium: {
              value: premium,
              capacity: capPremium,
            },
            diesel: {
              value: diesel,
              capacity: capDiesel,
            },
          },
          createdBy: user,
        });
        const updateRef = doc(db, "companies", user.companyId, "locations", locRef.id);
        await setDoc(updateRef, { locId: locRef.id }, { merge: true })
          .then(() => {
            setName("");
            setLoc("");
            setMagna(false);
            setCapDiesel(0);
            setCapMagna(0);
            setCapPremium(0);
            setDiesel(false);
            setPremium(false);
            setMembers([{ value: user, label: user.email }]);
            setModal(false);
            setShow(false);
            setLoading(false);
            var cc = company;
            cc.locationCount = cc.locationCount + 1;
            setCompany(cc);
            toast.success("¡Ubicación creada!");
            setChange((c) => !c);
          })
          .catch((er) => {
            alert(er);
            toast.error("Ha ocurrido un error");
            setModal(false);
            setLoading(false);
          });
      } else {
        setModal(false);
        setLoading(false);
        toast.error(
          "Por favor ingresa una dirección completa con código postal, numero exterior, municipio, estado y país"
        );
      }
    } else {
      setModal(false);
      setLoading(false);
      console.log(address);
      //   alert("Por favor llena todos los datos");
      toast.error("Por favor llena todos los datos");
    }
  };

  const validateInfo = () => {
    if (checkIfValid(name) && checkIfValid(address) && members.length >= 1) {
      setModal(true);
    } else {
      setModal(false);
      setLoading(false);
      toast.error("Por favor llena todos los datos");

      //   alert("Por favor llena todos los datos");
    }
  };

  const initial = async () => {
    setLoading(true);
    var resp = [];
    const locsRef = collection(db, "companies", user.companyId, "locations");
    const loccSnap = await getDocs(locsRef);
    loccSnap.forEach((e) => {
      resp.push(e.data());
    });
    if (resp.length >= 1) {
      setBusinessLocations(resp);
      setLoading(false);
    }

    var memb = [];
    company.members.forEach((member) => {
      memb.push({
        label: member.email,
        value: {
          name: member.name,
          lastName: member.lastName,
          email: member.email,
          uid: member.uid,
          permission: member.permission,
        },
      });
    });
    setOptions(memb);
    setLoading(false);
  };
  //   useEffect(() => {}, [company]);
  useEffect(() => {
    initial();
  }, [change, company]);

  return (
    <Fragment>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        name="end"
        style={{ width: "600px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title as="h3">Crear Sucursal</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          {/* <OffcanvasCreateProjectForm onClick={handleClose} /> */}

          <Form>
            <Row>
              {/* Project's Name */}
              <Col xs={12} className="mb-3">
                <Form.Group controlId="formProjectTitle">
                  <Form.Label>
                    Nombre <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    placeholder="Nombre de la sucursal"
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>

              {/* Dirección */}
              <Col xs={12} className="mb-3">
                <Form.Group controlId="formProjectBrief">
                  <Form.Label>
                    Dirección <span className="text-danger">*</span>
                  </Form.Label>
                  {/* <Form.Control
                    as="textarea"
                    rows={3}
                    value={loc}
                    required
                    onChange={(e) => setLoc(e.target.value)}
                    placeholder="Calle número, colonia, estado, país"
                  /> */}

                  {isLoaded && (
                    <Autocomplete
                      onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                      options={{ componentRestrictions: { country: "mx" } }}
                      onPlaceChanged={handlePlaceChanged}
                      className=""
                    >
                      <input
                        type="text"
                        className="places ps-3"
                        placeholder="Ingresa la dirección de tu negocio"
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        required
                      />
                    </Autocomplete>
                  )}
                </Form.Group>
              </Col>

              {/* Productos */}
              <Col xs={12} className="mb-3">
                <Form.Group controlId="formProjectBrief">
                  <Form.Label>
                    Productos <span className="text-danger">*</span>
                  </Form.Label>

                  <Row>
                    <Col xs={12} lg={4}>
                      <Form.Check
                        inline
                        label="Magna"
                        className="text-success"
                        name="group1"
                        type="checkbox"
                        id={`inline-checkbox-1`}
                        onChange={(e) => {
                          setMagna(e.target.checked);
                        }}
                      />
                      <Form.Label>
                        Capacidad Total del Tanque (L) <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        disabled={!magna}
                        value={capMagna}
                        placeholder="Capacidad (L)"
                        onChange={(e) => setCapMagna(e.target.value)}
                        required
                      />
                    </Col>

                    <Col xs={12} lg={4}>
                      <Form.Check
                        inline
                        label="Premium"
                        className="text-danger"
                        name="group1"
                        type="checkbox"
                        id={`inline-checkbox-2`}
                        onChange={(e) => {
                          setPremium(e.target.checked);
                        }}
                      />
                      <Form.Label>
                        Capacidad Total del Tanque (L) <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        className="text-black"
                        disabled={!premium}
                        value={capPremium}
                        placeholder="Capacidad (L)"
                        onChange={(e) => setCapPremium(e.target.value)}
                        required
                      />
                    </Col>

                    <Col xs={12} lg={4}>
                      <Form.Check
                        inline
                        label="Diesel"
                        name="group1"
                        type="checkbox"
                        id={`inline-checkbox-3`}
                        onChange={(e) => {
                          setDiesel(e.target.checked);
                        }}
                      />
                      <Form.Label>
                        Capacidad Total del Tanque (L) <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        disabled={!diesel}
                        value={capDiesel}
                        placeholder="Capacidad (L)"
                        onChange={(e) => setCapDiesel(e.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>

              {/* Team Members */}
              <Col xs={12} className="mb-3">
                <Form.Group controlId="formTeamMembers">
                  <Form.Label>
                    Miembros <span className="text-danger">*</span>
                  </Form.Label>
                  {/* <Form.Control
                    as={FormSelect}
                    placeholder="Assign to owner"
                    options={teamMembers}
                  /> */}

                  <Select
                    isMulti
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isClearable={false}
                    defaultValue={members}
                    placeholder="Selecciona"
                    value={members}
                    // options={[
                    //   { value: user, label: user.email },
                    //   { value: "strawberry", label: "Strawberry" },
                    //   { value: "vanilla", label: "Vanilla" },
                    // ]}
                    options={memberOptions}
                    onChange={(e) => {
                      setMembers(e);
                    }}
                  />
                </Form.Group>
              </Col>

              {/* Buttons */}
              <Col xs={12}>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    validateInfo();
                    // checkCp();
                  }}
                >
                  Crear
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                  }}
                  variant="outline-danger"
                  className="ms-2"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      {/* <section className="py-8 bg-light">
        <Container>
          <Row>
            <Col lg={8} md={10} xs={12}>
              <div>
                <div className="mb-4">
                  <h1 className=" fw-bold mb-4">
                    <span className="text-primary">{company.locationCount}</span> Sucursales
                  </h1>
                </div>
                <JobSearchBox /> 
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      <Modal show={modal} centered onHide={() => setModal(false)}>
        {loading ? (
          <Modal.Body className="py-5 d-flex align-items-center justify-content-center">
            <Spinner animation="border" variant="primary" className="me-2" />
          </Modal.Body>
        ) : (
          <div>
            <Modal.Header>
              <h2>Confirmar Creación</h2>
            </Modal.Header>

            <Modal.Body>
              <p className="fw-semi-bold">
                Estas a punto de crear una nueva sucursal, esto tendrá impacto en tu pago de
                mensualidad.
              </p>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="primary"
                onClick={(e) => {
                  e.preventDefault();
                  createLoc();
                }}
              >
                Crear
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setModal(false);
                }}
                variant="outline-danger"
                className="ms-2"
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </div>
        )}
      </Modal>
      <section className="py-8 bg-light">
        {/* <Row>
            <Col md={4} xl={3}>
              <JobFilters />
            </Col>
            <Col xl={9} md={8} className="mb-6 mb-md-0"> */}

        <Tab.Container defaultActiveKey="list">
          <Row className="row align-items-center justify-content-end mb-4">
            {/* <Col xs> */}
            {/* <p className="mb-0">{company.locationCount} Sucursales</p> */}
            {/* <GlobalFilter
                  filter={search}
                  setFilter={setSearch}
                  placeholder="Buscar por nombre"
                /> */}
            {/* </Col> */}
            <Col className="col-auto">
              <div className="d-flex ">
                {/* <GridListViewButton keyGrid="grid" keyList="list" /> */}
                <ButtonGroup className="me-3">
                  <Button
                    variant={grid ? "secondary" : "outline-secondary"}
                    onClick={(e) => {
                      e.preventDefault();
                      setGrid(true);
                    }}
                  >
                    <span className="fe fe-grid"></span>
                  </Button>
                  <Button
                    variant={grid ? "outline-secondary" : "secondary"}
                    onClick={(e) => {
                      e.preventDefault();
                      setGrid(false);
                    }}
                  >
                    <span className="fe fe-list"></span>
                  </Button>
                </ButtonGroup>
                {/* <FormSelect options={sortByOptions} placeholder="Sorting" /> */}

                {user.permission >= 4 && (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleShow();
                    }}
                  >
                    + Agregar Sucursal
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          {businessLocations.length >= 1 && !loading ? (
            <div>
              {grid ? (
                <div>{loading ? <Loader /> : <LocationGridView data={businessLocations} />}</div>
              ) : (
                <div>{loading ? <Loader /> : <LocationListView data={businessLocations} />}</div>
              )}
            </div>
          ) : (
            <div>
              {loading ? (
                <div
                  className="h-100 w-100 d-flex align-items-center justify-content-center"
                  style={{ minHeight: "10rem" }}
                >
                  {" "}
                  <Spinner animation="border" variant="primary" className="me-2" />
                </div>
              ) : (
                <p>No tienes ubicaciones por el momento</p>
              )}
            </div>
          )}
        </Tab.Container>

        {/* </Col> */}
        {/* </Row> */}
      </section>
      <ToastContainer position="bottom-right" />
    </Fragment>
  );
};

export default LocationList;
