// import node module libraries
import { Fragment } from "react";
import { Card, Col, ListGroup, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

// import custom components
import StatRightChart from "components/dashboard/common/stats/StatRightChart";
import UsersbyCountry from "components/dashboard/maps/UsersbyCountry";
import ApexCharts from "components/elements/charts/ApexCharts";
import { FlatPickr } from "components/elements/flat-pickr/FlatPickr";

// import sub components
import Browsers from "./Browsers";
import MostViewPages from "./MostViewPages";
import SocialMediaTraffic from "./SocialMediaTraffic";

// import MDI icons
import { mdiSquareRounded } from "@mdi/js";
import Icon from "@mdi/react";

// Import required data files
import {
  ActiveUserChartOptions,
  ActiveUserChartSeries,
  OperatingSystemChartOptions,
  OperatingSystemChartSeries,
  TrafficChannelChartOptions,
  TrafficChannelChartSeries,
} from "data/charts/ChartData";

const Analytics = () => {
  const theme = {
    primary: "var(--geeks-primary)",
    secondary: "var(--geeks-secondary)",
    success: "var(--geeks-success)",
    info: "var(--geeks-info)",
    warning: "var(--geeks-warning)",
    danger: "var(--geeks-danger)",
    dark: "var(--geeks-dark)",
    light: "var(--geeks-light)",
    white: "var(--geeks-white)",
    gray100: "var(--geeks-gray-100)",
    gray200: "var(--geeks-gray-200)",
    gray300: "var(--geeks-gray-300)",
    gray400: "var(--geeks-gray-400)",
    gray500: "var(--geeks-gray-500)",
    gray600: "var(--geeks-gray-600)",
    gray700: "var(--geeks-gray-700)",
    gray800: "var(--geeks-gray-800)",
    gray900: "var(--geeks-gray-900)",
    black: "var(--geeks-black)",
    lightPrimary: "var(--geeks-light-primary)",
    lightSecondary: "var(--geeks-light-secondary)",
    lightSuccess: "var(--geeks-light-success)",
    lightDanger: "var(--geeks-light-danger)",
    lightWarning: "var(--geeks-light-warning)",
    lightInfo: "var(--geeks-light-info)",
    darkPrimary: "var(--geeks-dark-primary)",
    darkSecondary: "var(--geeks-dark-secondary)",
    darkSuccess: "var(--geeks-dark-success)",
    darkDanger: "var(--geeks-dark-danger)",
    darkWarning: "var(--geeks-dark-warning)",
    darkInfo: "var(--geeks-dark-info)",
    transparent: "transparent",
    borderColor: "var(--geeks-border-color)",
  };
  const SessionChartSeries = [
    {
      name: "Total",
      data: [600, 1e3, 400, 2e3, 500, 900, 2500, 1800, 3800],
      colors: [theme.primary],
    },
    {
      name: "Premium",
      data: [1e3, 2e3, 800, 1200, 300, 1900, 1600, 2e3, 1e3],
      colors: [theme.danger],
    },
    {
      name: "Magna",
      data: [2200, 1e3, 3400, 900, 500, 2500, 3e3, 1e3, 2500],
      colors: [theme.info],
    },
  ];
  const SessionChartOptions = {
    chart: {
      toolbar: { show: !1 },
      height: 200,
      type: "line",
      zoom: { enabled: !1 },
    },
    dataLabels: { enabled: !1 },
    stroke: { width: [4, 3, 3], curve: "smooth", dashArray: [0, 5, 4] },
    legend: { show: !1 },
    colors: [theme.primary, theme.danger, theme.success],
    markers: { size: 0, hover: { sizeOffset: 6 } },
    xaxis: {
      categories: [
        "01 Jan",
        "02 Jan",
        "03 Jan",
        "04 Jan",
        "05 Jan",
        "06 Jan",
        "07 Jan",
        "08 Jan",
        "09 Jan",
        "10 Jan",
        "11 Jan",
        "12 Jan",
      ],
      labels: {
        style: {
          colors: [theme.gray500],
          fontSize: "12px",
          fontFamily: "Inter",
          cssClass: "apexcharts-xaxis-label",
        },
      },
      axisBorder: {
        show: !0,
        color: theme.borderColor,
        height: 0,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: !0,
        borderType: "solid",
        color: theme.borderColor,
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: [theme.gray500],
          fontSize: "12px",
          fontFamily: "Inter",
          cssClass: "apexcharts-xaxis-label",
        },
        offsetX: -12,
        offsetY: 0,
      },
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (e) {
              return e + " ";
            },
          },
        },
        {
          title: {
            formatter: function (e) {
              return e + " ";
            },
          },
        },
        {
          title: {
            formatter: function (e) {
              return e;
            },
          },
        },
      ],
    },
    grid: { borderColor: theme.borderColor },
    responsive: [
      { breakpoint: 480, options: { chart: { height: 300 } } },
      { breakpoint: 1441, options: { chart: { height: 360 } } },
      { breakpoint: 1980, options: { chart: { height: 400 } } },
      { breakpoint: 2500, options: { chart: { height: 470 } } },
      { breakpoint: 3000, options: { chart: { height: 450 } } },
    ],
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex justify-content-between align-items-center">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-0 h2 fw-bold">Analytics</h1>
            </div>
            <div className="d-flex">
              <div className="input-group me-3  ">
                <FlatPickr value={""} />
                <span className="input-group-text text-muted" id="basic-addon2">
                  <i className="fe fe-calendar"></i>
                </span>
              </div>
              <Link to="#" className="btn btn-primary">
                Setting
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={3} lg={6} md={12} sm={12}>
          <StatRightChart
            title="USER"
            value="30.6k"
            summary="Number of sales"
            summaryValue="+20.9$"
            summaryIcon="up"
            showSummaryIcon
            classValue="mb-4"
            chartName="UserChart"
          />
        </Col>

        <Col xl={3} lg={6} md={12} sm={12}>
          <StatRightChart
            title="UNIQUE VISITORS"
            value="256k"
            summary="Number of pending"
            summaryValue="5%"
            summaryIcon="down"
            showSummaryIcon
            classValue="mb-4"
            chartName="VisitorChart"
          />
        </Col>

        <Col xl={3} lg={6} md={12} sm={12}>
          <StatRightChart
            title="BOUNCE RATE"
            value="51.46%"
            summary="Students"
            summaryValue="+1200"
            summaryIcon="up"
            showSummaryIcon
            classValue="mb-4"
            chartName="BounceChart"
          />
        </Col>

        <Col xl={3} lg={6} md={12} sm={12}>
          <StatRightChart
            title="AVERAGE VISIT TIME"
            value="1m:17s"
            summary="Instructor"
            summaryValue="12%"
            summaryIcon="up"
            showSummaryIcon
            classValue="mb-4"
            chartName="AverageVisitTimeChart"
          />
        </Col>
      </Row>

      {/* Sessions + Active User Row */}
      <Row>
        <Col xl={8} lg={12} md={12} className="mb-4">
          <Card className="h-100">
            <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
              <h4 className="mb-0">Sessions</h4>
            </Card.Header>
            <Card.Body>
              <ApexCharts options={SessionChartOptions} series={SessionChartSeries} type="line" />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4} lg={12} md={12} className="mb-4">
          <Card className="h-100">
            <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
              <h4 className="mb-0">Active User</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <span className="fw-semi-bold">30 days</span>
                  <h1 className="fw-bold mt-2 mb-0 h2">12,698</h1>
                  <p className="text-success fw-semi-bold mb-0">
                    <i className="fe fe-trending-up me-1"></i>4.6%
                  </p>
                </Col>
                <Col>
                  <span className="fw-semi-bold">7 days</span>
                  <h1 className="fw-bold mt-2 mb-0 h2">2.65K</h1>
                  <p className="text-danger fw-semi-bold mb-0">
                    <i className="fe fe-trending-down me-1"></i>4.6%
                  </p>
                </Col>
                <Col>
                  <span className="fw-semi-bold">1 days</span>
                  <h1 className="fw-bold mt-2 mb-0 h2">1.34K</h1>
                  <p className="text-success fw-semi-bold mb-0">
                    <i className="fe fe-trending-up me-1"></i>4.6%
                  </p>
                </Col>
              </Row>
              <ApexCharts
                options={ActiveUserChartOptions}
                series={ActiveUserChartSeries}
                type="bar"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* end of Sessions + Active User Row */}

      {/* Users by Country + Traffic Channel + Operating System  */}
      <Row>
        <Col xl={4} lg={12} md={12} className="mb-4">
          <Card className="h-100">
            <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
              <h4 className="mb-0">Users by Country</h4>
            </Card.Header>
            <Card.Body className="py-0">
              <UsersbyCountry />
              <Table borderless size="sm">
                <tbody>
                  <tr>
                    <td>United States</td>
                    <td>22,120</td>
                    <td>34.54%</td>
                  </tr>
                  <tr>
                    <td>India</td>
                    <td>12,756</td>
                    <td>22.43%</td>
                  </tr>
                  <tr>
                    <td>United Kingdom</td>
                    <td>8,864</td>
                    <td>34.54%</td>
                  </tr>
                  <tr>
                    <td>Sweden</td>
                    <td>6,749</td>
                    <td>5.29%</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col xl={4} lg={12} md={12} className="mb-4">
          <Card className="h-100">
            <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
              <h4 className="mb-0">Traffic Channel</h4>
            </Card.Header>
            <Card.Body className="p-1">
              <ApexCharts
                options={TrafficChannelChartOptions}
                series={TrafficChannelChartSeries}
                type="donut"
                height={260}
              />
              <Table size="sm" className="mt-5 mb-0 ms-2" borderless>
                <tbody>
                  <tr>
                    <td>
                      <Icon path={mdiSquareRounded} className="text-primary fs-5 me-2" size={0.6} />
                      Organic Search
                    </td>
                    <td>2,120</td>
                    <td>4.54%</td>
                  </tr>
                  <tr>
                    <td>
                      <Icon path={mdiSquareRounded} className="text-success fs-5 me-2" size={0.6} />
                      Direct
                    </td>
                    <td>639</td>
                    <td>4.37%</td>
                  </tr>
                  <tr>
                    <td>
                      <Icon path={mdiSquareRounded} className="text-danger fs-5 me-2" size={0.6} />
                      Refferrals
                    </td>
                    <td>520</td>
                    <td>45.14%</td>
                  </tr>
                  <tr>
                    <td>
                      <Icon path={mdiSquareRounded} className="text-info fs-5 me-2" size={0.6} />
                      Social Media
                    </td>
                    <td>116</td>
                    <td>12.24%</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col xl={4} lg={12} md={12} className="mb-4">
          <Card className="h-100">
            <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
              <h4 className="mb-0">Operating System</h4>
            </Card.Header>
            <Card.Body>
              <ApexCharts
                options={OperatingSystemChartOptions}
                series={OperatingSystemChartSeries}
                type="polarArea"
                height={350}
              />
              <div className="mt-4 d-flex justify-content-center">
                <ListGroup as="ul" bsPrefix="list-inline" className="mb-0">
                  <ListGroup.Item as="li" bsPrefix="list-inline-item mx-3">
                    <h5 className="mb-0 d-flex align-items-center fs-5 lh-1">
                      <Icon path={mdiSquareRounded} className="text-danger fs-5 me-2" size={0.6} />
                      Window
                    </h5>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="list-inline-item mx-3">
                    <h5 className="mb-0 d-flex align-items-center  fs-5 lh-1">
                      <Icon path={mdiSquareRounded} className="text-success fs-5 me-2" size={0.6} />
                      macOS
                    </h5>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="list-inline-item mx-3">
                    <h5 className="mb-0 d-flex align-items-center  fs-5 lh-1">
                      <Icon path={mdiSquareRounded} className="text-primary fs-5 me-2" size={0.6} />
                      Linux
                    </h5>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="list-inline-item mx-3">
                    <h5 className="mb-0 d-flex align-items-center  fs-5 lh-1">
                      <Icon path={mdiSquareRounded} className="text-info fs-5 me-2" size={0.6} />
                      Android
                    </h5>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* end of Users by Country + Traffic Channel + Operating System  */}

      {/* Browsers + Social Media Traffic + Most View Pages  */}
      <Row>
        <Col xl={4} lg={12} md={12} className="mb-4">
          <Browsers title="Browsers" />
        </Col>

        <Col xl={4} lg={12} md={12} className="mb-4">
          <SocialMediaTraffic title="Social Media Traffic" />
        </Col>

        <Col xl={4} lg={12} md={12} className="mb-4">
          <MostViewPages title="Most View Pages" />
        </Col>
      </Row>
      {/* end of Users by Country + Traffic Channel + Operating System  */}
    </Fragment>
  );
};

export default Analytics;
