// import node module libraries
import { Fragment } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";

import Logo from "assets/images/brand/logo/logo-icon.svg";
// import { getAuth } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";
import { Link } from "react-router-dom";

// import media files
import RegistrationProcess from "./registrationProgress/RegistrationProcess";

const CompanyRegistration = () => {
  // const auth = getAuth();
  // const db = getFirestore();

  // const [pw, setPw] = useState("");
  // const [pw2, setPw2] = useState("");
  // const [email, setEmail] = useState("");
  // const [name, setName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [phone, setPhone] = useState("");
  // const [disabled, setDisabled] = useState(true);
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {}, []);
  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        <Col lg={5} md={5} className="py-8 py-xl-0">
          <Card>
            <div className="mt-4 d-flex flex-column align-items-center">
              <Link to="/landing">
                <Image src={Logo} alt="" height={110} />
              </Link>
            </div>
            {/* {loading ? (
              <div
                className="w-100 d-flex align-items-center justify-content-center"
                style={{ minHeight: "10rem" }}
              >
                <Loader />
              </div>
            ) : ( */}
            <Card.Body className="px-4">
              <RegistrationProcess />
            </Card.Body>
            {/* // )} */}
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CompanyRegistration;
