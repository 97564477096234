// import node module libraries
import { Fragment } from "react";

// import sub components
import CommonHeaderTabs from "../CommonHeaderTabs";
import FilesTable from "./FilesTable";

const ConvolFiles = () => {
  return (
    <Fragment>
      {/* page header tabs */}
      <CommonHeaderTabs />

      {/* files listing table */}
      <FilesTable />
    </Fragment>
  );
};

export default ConvolFiles;
