// import node module libraries
import { FormSelect } from "components/elements/form-select/FormSelect";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Modal,
  Offcanvas,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { Edit, List, MoreVertical, Trash } from "react-feather";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { useFilters, useGlobalFilter, usePagination, useTable } from "react-table";
// import custom components
// import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import { httpsCallable } from "firebase/functions";
import { functions } from "index";

// import data files
// import QuizResultsData from "data/marketing/quiz/QuizResultsData";
import { DashboardContext } from "context/Context";

// import profile layout wrapper

const TeamList = () => {
  const { user, company, setCompany } = useContext(DashboardContext);
  const [selectedMember, setSelected] = useState(null);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [modalDelete, setModalDelete] = useState(false);
  const [modalInvite, setModalInvite] = useState(false);

  // const [pw, setPw] = useState("");
  const db = getFirestore();
  const [permission, setPermission] = useState(0);
  const [change, setChange] = useState();

  const checkIfValid = (value) => {
    console.log(value);
    if (value != null && value !== "" && value !== " " && value !== undefined) {
      return true;
    } else {
      return false;
    }
  };

  const addUser = () => {
    const createMember = httpsCallable(functions, "createMember");
    var pw = create_pw();
    if (checkIfValid(email) && checkIfValid(name) && checkIfValid(lastName) && permission > 0) {
      setModalInvite(true);
      setLoading(true);
      createMember({
        name: name,
        lastName: lastName,
        email: email,
        companyId: user.companyId,
        pw: pw,
        permission: parseInt(permission),
        dateCreated: new Date(),
      })
        .then((result) => {
          setShow(false);
          setLoading(false);
          setChange((e) => !e);
          console.log("User created with UID:", "pw: ", pw);
          toast.success("¡Miembro invitado!", {
            onClose: () => setModalInvite(false),
          });
        })
        .catch((error) => {
          setLoading(false);
          setModalInvite(false);
          console.error("Error creating user:", error);
          toast.error("Ha ocurrido un error", {
            onClose: () => setModalInvite(false),
          });
        });
    } else {
      // alert("Por favor llena todos los datos");
      toast.error("Por favor llena todos los datos");
    }
  };

  const deleteUser = () => {
    const deleteMember = httpsCallable(functions, "deleteMember");
    setLoading(true);
    deleteMember({
      userUid: selectedMember.uid,
      companyId: user.companyId,
    })
      .then((result) => {
        toast.success("Miembro eliminado");

        setModalDelete(false);
        setLoading(false);

        setChange((e) => !e);
        console.log("User deleted");
      })
      .catch((error) => {
        toast.error("Ha ocurrido un error");

        setLoading(false);
        console.error("Error deleting user:", error);
      });
  };

  function create_pw() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx".replace(/[x]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    console.log(uuid);
    return uuid;
  }

  const permissions = [
    { label: "Administrador", value: 4 },
    { label: "Gerente", value: 3 },
    { label: "Auditor", value: 2 },
  ];
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle shadow border-primary"
    >
      {children}
    </Link>
  ));
  const ActionMenu = ({ member }) => {
    return (
      <Dropdown style={{ position: "absolute" }}>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          {/* <Dropdown.Header>SETTINGS</Dropdown.Header> */}

          {member.uid == company.ownerId ? (
            <div>
              {user.uid == member.uid && (
                <Dropdown.Item eventKey="1">
                  {" "}
                  <Edit size="15px" className="dropdown-item-icon" /> Editar
                </Dropdown.Item>
              )}
            </div>
          ) : (
            <Dropdown.Item eventKey="1">
              {" "}
              <Edit size="15px" className="dropdown-item-icon" /> Editar
            </Dropdown.Item>
          )}

          <Dropdown.Item
            eventKey="2"
            onClick={(e) => {
              e.preventDefault();
              setSelected(member);
            }}
          >
            {" "}
            <List size="15px" className="dropdown-item-icon" /> Ver Actividad
          </Dropdown.Item>
          {!(member.uid == company.ownerId) && (
            <Dropdown.Item
              eventKey="2"
              className="text-danger"
              onClick={(e) => {
                e.preventDefault();
                setSelected(member);
                setModalDelete(true);
              }}
            >
              {" "}
              <Trash size="15px" className="dropdown-item-icon text-danger" /> Eliminar
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const sortOptions = [
    { value: "Free", label: "Free" },
    { value: "Newest", label: "Newest" },
    { value: "Oldest", label: "Oldest" },
  ];

  const columns = useMemo(
    () => [
      // { accessor: "image", Header: "", show: false },
      {
        accessor: "name",
        Header: "Nombre",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex align-items-center">
              {/* <Image src={row.original.image} alt="" className="rounded-circle avatar-md me-2" /> */}
              <h5 className="mb-0">
                {value} {row.original.lastName}
              </h5>
            </div>
          );
        },
      },
      { accessor: "email", Header: "Email" },
      {
        accessor: "permission",
        Header: "Permiso",
        Cell: ({ value }) => {
          if (value == 4) {
            return "Administrador";
          } else if (value == 3) {
            return "Gerente";
          } else if (value == 2) {
            return "Auditor";
          }
        },
      },
      // { accessor: "finishingTime", Header: "Finishing Time" },
      {
        accessor: "uid",
        Header: " ",
        show: true,

        Cell: ({ value, row }) => {
          return (
            <div className="d-flex align-items-center">
              {/* <Image src={row.original.image} alt="" className="rounded-circle avatar-md me-2" /> */}
              <ActionMenu member={row.original} />
            </div>
          );
        },
      },
    ],
    []
  );

  // const data = useMemo(() => QuizResultsData, []);

  const initial = async () => {
    const companyRef = doc(db, "companies", user.companyId);
    const companySnap = await getDoc(companyRef);
    if (companySnap.exists()) {
      setCompany(companySnap.data());
      setData(companySnap.data().members);
      setLoading(false);
    }
  };

  useEffect(() => {
    initial();
  }, [change]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  const setFromPage = (recordsPerPage, pageNo) => recordsPerPage * pageNo + 1;
  const setToPage = (recordsPerPage, pageNo, recordsOnCurrentPage) => {
    return recordsOnCurrentPage < recordsPerPage
      ? recordsPerPage * (pageNo + 1) - (recordsPerPage - recordsOnCurrentPage)
      : recordsPerPage * (pageNo + 1);
  };
  return loading ? (
    <div
      className="h-100 w-100 d-flex align-items-center justify-content-center"
      style={{ minHeight: "10rem" }}
    >
      {" "}
      <Spinner animation="border" variant="primary" className="me-2" />
    </div>
  ) : (
    <Card className="mb-4">
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        name="end"
        style={{ width: "600px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title as="h3">Agregar Miembro</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          {/* <OffcanvasCreateProjectForm onClick={handleClose} /> */}

          <Form>
            <Row>
              {/* Project's Name */}
              <Col md={6} xs={12} className="mb-3">
                <Form.Group controlId="formProjectTitle">
                  <Form.Label>
                    Nombre <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    placeholder="Nombre"
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>

              <Col md={6} xs={12} className="mb-3">
                <Form.Group controlId="formProjectBrief">
                  <Form.Label>
                    Apellido <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    required
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Apellido"
                  />
                </Form.Group>
              </Col>

              {/* Description */}
              <Col md={6} xs={12} className="mb-3">
                <Form.Group controlId="formEmailBrief">
                  <Form.Label>
                    Email <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="ejemplo@ejemplo.com"
                  />
                </Form.Group>
              </Col>

              <Col md={6} xs={12} className="mb-3">
                <Form.Group controlId="formPermisoBrief">
                  <Form.Label>
                    Permiso <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as={FormSelect}
                    placeholder="Asigna un tipo de permiso"
                    options={permissions}
                    onChange={(e) => {
                      setPermission(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>

              {/* Buttons */}
              <Col xs={12}>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    addUser();
                  }}
                >
                  Crear
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                  }}
                  variant="outline-danger"
                  className="ms-2"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={modalInvite}
        centered
        onHide={() => {
          setName("");
          setEmail("");
          setLastName("");
          setModalInvite(false);
        }}
      >
        {loading ? (
          <Modal.Body className="py-5 d-flex align-items-center justify-content-center">
            <Spinner animation="border" variant="primary" className="me-2" />
          </Modal.Body>
        ) : (
          <div>
            <Modal.Header>
              <h2>¡Miembro invitado!</h2>
            </Modal.Header>

            <Modal.Body>
              <p className="fw-semi-bold">
                {name} deberá de recibir un correo a {email} con su cuenta y contraseña para iniciar
                sesión y ser parte de tu equipo.
                <br /> IMPORTANTE: El correo electrónico puede llegar a spam o junk mail.
              </p>
            </Modal.Body>
          </div>
        )}
      </Modal>

      <Modal show={modalDelete} centered onHide={() => setModalDelete(false)}>
        {loading ? (
          <Modal.Body className="py-5 d-flex align-items-center justify-content-center">
            <Spinner animation="border" variant="primary" className="me-2" />
          </Modal.Body>
        ) : (
          <div>
            <Modal.Header>
              <h2>¿Estás Seguro?</h2>
            </Modal.Header>

            <Modal.Body>
              <p className="fw-semi-bold">
                Estas a punto de eliminar el usuario "{selectedMember && selectedMember.email}".
                Esta acción es irreversible.
              </p>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="primary"
                onClick={(e) => {
                  e.preventDefault();
                  deleteUser();
                }}
              >
                Eliminar
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setModalDelete(false);
                }}
                variant="outline-danger"
                className="ms-2"
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </div>
        )}
      </Modal>
      <Card.Header className="d-flex justify-content-between">
        <h3 className="mb-0">Equipo</h3>

        <Button
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            setShow(true);
          }}
        >
          + Agregar Miembro
        </Button>
      </Card.Header>
      {/* <Card.Body className="border-bottom">
        <Row className="row-cols-lg-3 row-cols-1">
          <Col>
            <Card className="bg-gray-100 shadow-none mb-3 mb-lg-0">
              <Card.Body>
                <h4 className="mb-0">Participate</h4>
                <div className="mt-5 d-flex justify-content-between align-items-center lh-1">
                  <div>
                    <span className="fs-3 text-dark fw-semi-bold">27</span>
                  </div>
                  <div className="align-middle">
                    <i className="fe fe-users h2 text-danger"></i>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="bg-gray-100 shadow-none mb-3 mb-lg-0">
              <Card.Body>
                <h4 className="mb-0">Scores</h4>
                <div className="mt-5 d-flex justify-content-between align-items-center lh-1">
                  <div>
                    <span className="fs-3 text-dark fw-semi-bold">82</span>
                  </div>
                  <div className="align-middle">
                    <i className="fe fe-clipboard h2 text-primary"></i>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="bg-gray-100 shadow-none mb-3 mb-lg-0">
              <Card.Body>
                <h4 className="mb-0">Average Time</h4>
                <div className="mt-5 d-flex justify-content-between align-items-center lh-1">
                  <div>
                    <span className="fs-3 text-dark fw-semi-bold">00:00:42</span>
                  </div>
                  <div className="align-middle">
                    <i className="fe fe-clock h2 text-success"></i>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body> */}
      {/* <Card.Body className="card-body border-bottom">
        <Form className="row">
          <Col lg={9} md={7} xs={12} className="mb-lg-0 mb-2">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} placeholder="Search" />
          </Col>
          <Col lg={3} md={5} xs={12}>
            <Form.Control as={FormSelect} placeholder="Sort by" options={sortOptions} />
          </Col>
        </Form>
      </Card.Body> */}
      {/* table */}

      <Table
        responsive
        hover
        {...getTableProps()}
        className="text-nowrap table-centered"
        // style={{ overflow: "visible" }}
      >
        <thead className="table-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Card.Footer className="card-footer">
        {/* Pagination */}
        <div className="d-flex align-items-center w-100 justify-content-center">
          {/* <span>
            Showing {setFromPage(page.length, pageIndex)} -
            {setToPage(page.length, pageIndex, page.length)} of {data.length} entries
          </span> */}
          <Pagination
            previousPage={previousPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            nextPage={nextPage}
          />
        </div>
      </Card.Footer>
      <ToastContainer position="bottom-right" />
    </Card>
  );
};

export default TeamList;
