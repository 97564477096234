// import node module libraries
import { Col, Container, Row } from "react-bootstrap";

// import custom components
import StatTopBigIcon from "components/marketing/common/stats/StatTopBigIcon";

const AcademyStats = () => {
  return (
    <section className="pb-14 bg-white">
      <Container>
        <Row>
          <Col lg={3} md={6} className="border-top-md border-bottom border-end-md ">
            <StatTopBigIcon
              title="Accede cuando y desde donde quieras"
              value="Basado en la Nube"
              iconName="cloud"
              colorVariant="info"
            />
          </Col>
          <Col lg={3} md={6} className="border-top-md border-bottom border-end-lg ">
            <StatTopBigIcon
              title="Agrega a tu equipo y hasta auditores"
              value="Multiusuario"
              iconName="users"
              colorVariant="warning"
            />
          </Col>
          <Col lg={3} md={6} className="border-top-lg border-bottom border-end-md ">
            <StatTopBigIcon
              title="Capacitación, soporte y tutoriales incluídos"
              value="Capacitación y Soporte"
              iconName="tv"
              colorVariant="primary"
            />
          </Col>
          <Col lg={3} md={6} className="border-top-lg border-bottom ">
            <StatTopBigIcon
              title="Guarda de manera segura tus bitácoras"
              value="Seguro y Confiable"
              iconName="lock"
              colorVariant="success"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AcademyStats;
