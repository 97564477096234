// import node module libraries
import Loader from "components/elements/miscellaneous/Loader";
import { DashboardContext } from "context/Context";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { Fragment, useContext, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Row } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { toast, ToastContainer } from "react-toastify";

const BillingSetting = () => {
  const { user, setUser } = useContext(DashboardContext);
  const [cfdi, setcfdi] = useState(user.cfdi);
  const [rfc, setRfc] = useState(user.rfc);
  const [razon, setRazon] = useState(user.razon);
  const [constUrl, setConstUrl] = useState(user.constUrl);
  const [options, setOptions] = useState([
    { label: "Gastos en General", value: "Gastos-en-general" },
    { label: "Por Definir", value: "por-definir" },
  ]);
  const storage = getStorage();
  const db = getFirestore();
  const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ""),
  });
  const [loading, setLoading] = useState(false);
  const checkIfValid = (value) => {
    if (
      value != null &&
      value !== "" &&
      value !== " " &&
      value !== undefined &&
      value.trim().length >= 1
      //    &&
      //   /^[a-zA-ZÁáÉéÍí.,#ÓóÚúÑñüÜ\s]+$/g.test(value)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleCreate = (inputValue) => {
    // loading(true);
    // setTimeout(() => {
    const newOption = createOption(inputValue);
    //   setIsLoading(false);
    setOptions((prev) => [...prev, newOption]);
    setcfdi(newOption);
    // }, 1000);
  };

  const saveInfo = async () => {
    if (
      checkIfValid(cfdi.value) &&
      checkIfValid(rfc) &&
      checkIfValid(razon) &&
      checkIfValid(constUrl)
    ) {
      setLoading(true);
      const docRef = doc(db, "users", user.uid);
      await setDoc(
        docRef,
        { cfdiValue: cfdi.value, cfdi: cfdi, constUrl: constUrl, razon: razon, rfc: rfc },
        { merge: true }
      )
        .then(() => {
          var uuu = user;
          uuu.cfdi = cfdi.value;
          uuu.constUrl = constUrl;
          uuu.rfc = rfc;
          uuu.razon = razon;
          setUser(uuu);
          setLoading(false);
          toast.success("¡Información guardada!");
        })
        .catch((e) => {
          //   alert(e.message);
          toast.error("Ha ocurrido un error");
        });
    } else {
      console.log(
        checkIfValid(cfdi.value),
        checkIfValid(rfc),
        checkIfValid(razon),
        checkIfValid(constUrl)
      );
      setLoading(false);

      toast.error("Por favor llena todos los datos");
    }
  };
  useEffect(() => {
    const docRef = doc(db, "users", user.uid);
  }, []);
  //Upload const sit fisc
  const uploadSatDoc = (event) => {
    setLoading(true);
    var companyFolderRef = ref(storage, `companies/${user.companyId}/billing/${event.name}`);
    const uploadTask = uploadBytes(companyFolderRef, event)
      .then((snapshot) => {
        return getDownloadURL(companyFolderRef);
      })
      .then((url) => {
        setLoading(false);
        console.log(url);
        return setConstUrl(url);
      })
      .catch((er) => {
        setLoading(false);
        console.log(er);
        toast.error(er.message);
      });
  };
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between w-100">
            <div className="mb-3 mb-md-0 w-100">
              <h1 className="mb-1 h2 font-weight-bold">Facturación</h1>
              {/* <Breadcrumb>
            <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Settings</Breadcrumb.Item>
            <Breadcrumb.Item active>Google Setting</Breadcrumb.Item>
          </Breadcrumb> */}
            </div>
          </div>
          <Card className="mb-4">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <div>
                <h4 className="mb-2">Datos para Facturación</h4>
                <p className="mb-0">
                  La factura correspondiente a los pagos del servicio se generarán en base a estos
                  datos. Recuerda dar click al botón de guardar cada que modifiques los datos.
                </p>
              </div>

              {/* <Form>
            <Form.Check type="checkbox" label="" className=" form-switch" onChange={onChange} />
          </Form> */}
            </Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col lg={6} md={12} className="mb-3">
                    {/* Google Map API key */}
                    <Form.Label>
                      RFC <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="trackingPage"
                      placeholder="AAAAAA...."
                      required
                      value={rfc}
                      onChange={(e) => {
                        setRfc(e.target.value);
                      }}
                    />
                  </Col>

                  <Col lg={6} md={12} className="mb-3">
                    {/* Map Latitude */}
                    <Form.Label>
                      Razón Social <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="latitudePage1"
                      placeholder="e.g. S.A de C.V"
                      required
                      value={razon}
                      onChange={(e) => {
                        setRazon(e.target.value);
                      }}
                    />
                  </Col>
                  <Col lg={6} md={12} className="mb-3">
                    {/* Map Longitude */}
                    <Form.Label>
                      Uso de CFDI <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    {/* <Form.Control type="text" id="latitudePage2" placeholder="e.g. 74.6333" required /> */}

                    <CreatableSelect
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder={cfdi.value ? cfdi.value : "Selecciona o escribe"}
                      options={options}
                      value={cfdi}
                      onCreateOption={handleCreate}
                      onChange={(e) => {
                        setcfdi(e);
                      }}
                    />
                  </Col>

                  <Col lg={6} md={12} className="mb-3">
                    <Form.Label>
                      Constancia de Situación Fiscal <span className="text-danger">*</span>{" "}
                    </Form.Label>

                    {constUrl != "" ? (
                      <div>
                        <Badge bg="success">
                          <strong>Subida</strong>
                        </Badge>
                        <a target="_blank" href={constUrl} className="btn btn-link me-0 ms-3">
                          Ver
                        </a>
                        <Button
                          variant="link"
                          className="ms-0 pl-0"
                          onClick={(e) => {
                            e.preventDefault();
                            setConstUrl("");
                          }}
                        >
                          Cambiar
                        </Button>
                      </div>
                    ) : (
                      <Form.Control
                        type="file"
                        id="const"
                        size="md"
                        onChange={(ev) => {
                          var file = ev.target.files[0];
                          uploadSatDoc(file);
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} className="d-flex justify-content-end">
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        saveInfo();
                      }}
                    >
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </div>
      )}
      <ToastContainer position="bottom-right" />
    </Fragment>
  );
};

export default BillingSetting;
