// import node module libraries
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

// import custom components

// import media files
import Logo from "assets/images/brand/logo/logo.png";

// import data files
// import NavbarDefaultRoutes from "routes/marketing/NavbarDefault";

const NavbarLanding = ({ transparent }) => {
  const [expandedMenu, setExpandedMenu] = useState(false);
  const auth = getAuth();

  const [isUser, setIsUser] = useState(false);
  const logout = () => {
    signOut(auth)
      .then(() => {
        setIsUser(false);
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
        setIsUser(true);
      }
    });
  }, []);
  return (
    <Fragment>
      <Navbar
        onToggle={(collapsed) => setExpandedMenu(collapsed)}
        expanded={expandedMenu}
        expand="lg"
        className={`navbar navbar-default shadow-none ${transparent ? "navbar-transparent" : ""}`}
      >
        <Container className="px-0">
          <Navbar.Brand as={Link} to="/">
            <Image src={Logo} alt="" height={60} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="icon-bar top-bar mt-0"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              {/* {NavbarDefaultRoutes.map((item, index) => {
                if (item.children === undefined) {
                  return (
                    <Nav.Link key={index} as={Link} to={item.link}>
                      {item.menuitem}
                    </Nav.Link>
                  );
                } else {
                  return (
                    <NavDropdownMain
                      item={item}
                      key={index}
                      onClick={(value) => setExpandedMenu(value)}
                    />
                  );
                }
              })} */}
              {/* <DocumentMenu /> */}
            </Nav>
            <Nav className="navbar-nav navbar-right-wrap ms-auto d-flex align-items-center nav-top-wrap">
              {/* <DarkLightMode className="me-2" /> */}

              <Nav.Link as={Link} to="/contact" className="fw-semi-bold text-dark p-1">
                Contacto
              </Nav.Link>
              {isUser ? (
                <div className={`mt-3 mt-lg-0`}>
                  <Nav.Link as={Link} to="/" bsPrefix="btn" className="btn btn-primary ms-3">
                    Ir al Tablero
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    bsPrefix="btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      logout();
                    }}
                    className="btn btn-dark ms-1"
                  >
                    Cerrar Sesión
                  </Nav.Link>
                </div>
              ) : (
                <div className={`mt-3 mt-lg-0`}>
                  <Nav.Link
                    as={Link}
                    to="/sign-in"
                    bsPrefix="btn"
                    className="btn btn-outline-primary ms-3 text-black"
                  >
                    Inicia Sesión
                  </Nav.Link>

                  {/* <Nav.Link as={Link} bsPrefix="btn" to="/sign-up" className="btn btn-dark ms-1">
                    Regístrate
                  </Nav.Link> */}
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Fragment>
  );
};

// Specifies the default values for props
NavbarLanding.defaultProps = {
  transparent: false,
};

// Typechecking With PropTypes
NavbarLanding.propTypes = {
  transparent: PropTypes.bool,
};

export default NavbarLanding;
